import React, { useEffect } from "react"
import { loadVouched, removeVouchedScript } from "../../utils/vouchedScript"
import { useAuthContext } from "../../contexts/AuthContext"
import { useLocation, useNavigate } from "react-router-dom"
import { FailurePopup } from "./FailurePopup"
import { VOUCHED_APP_ID } from "../../utils"
import Logo from "../../assets/images/logo.svg"
import { ALL_ROUTES } from "../../app-config"

export const VouchedVerification = () => {
  const nav = useNavigate()
  const {
    state: locationState
  } = useLocation()
  const { verification: infoSubmittedByUser, updateUserProfile } =
    useAuthContext()
  const [failureMessage, setFailureMessage] = React.useState()

  const config = {
    appId: VOUCHED_APP_ID,

    // your webhook for POST verification processing
    callbackURL: "https://www.vouched.id/",

    // optional verification information for comparison
    verification: {
      firstName: "", // locationState?.verificationPayload?.firstName || "",
      lastName: "", // locationState?.verificationPayload?.lastName || ""
      email: "",
      phone: ""
    },

    // mobile handoff fields, a job will be created automatically if true
    crossDevice: true,
    crossDeviceQRCode: true,
    crossDeviceSMS: true,

    // have the user confirm information
    userConfirmation: {
      confirmData: false, // disable the data screen (https://docs.vouched.id/docs/recognized-ids)
      confirmImages: false
    }, // also make sure jobCompleted = true

    // callback during initialization of the web app
    onInit: ({ token, job }) => {
      console.log("[VOUCHED] (onInit)", job)
    },

    // callback when a user submits a photo
    onSubmit: ({ stage, attempts, job }) => {
      console.log("[VOUCHED] (onSubmit) photo submitted", job)
    },

    // called when a job is completed.
    onDone: job => {
      // token used to query jobs
      const { result: vouchedResult } = job
      console.log(
        "[VOUCHED] (onDone) Info Submitted by User: ",
        infoSubmittedByUser
      )
      console.log("[VOUCHED] (onDone) === VERIFICATION PROCESS COMPLETE ===")
      console.log(job)
      console.log("[VOUCHED] (onDone) Vouched Result: ", vouchedResult?.success === true ? 'SUCCESS' : 'FAILURE')
      console.log("[VOUCHED] (onDone) --- Comparison (Vouched Result --- User Input) ---")
      console.log(`${vouchedResult?.firstName} --- ${infoSubmittedByUser.firstName}`)
      console.log(`${vouchedResult?.lastName} --- ${infoSubmittedByUser.lastName}`)
      console.log(`${vouchedResult?.birthDate} --- ${infoSubmittedByUser.birthDate}`)
      console.log(`job.status =`, job?.status)
      console.log(`job.completed =`, job?.completed)

      // remove script
      removeVouchedScript()

      if (
        // vouchedResult?.firstName === infoSubmittedByUser.firstName &&
        // vouchedResult?.lastName === infoSubmittedByUser.lastName &&
        // vouchedResult?.birthDate === infoSubmittedByUser.birthDate &&
        vouchedResult?.success === true // &&
        // job?.status === "completed" &&
        // job?.completed === true
      ) {
        console.log("[VOUCHED] (onDone) VERIFIED ALL GOOD!")
        updateUserProfile(infoSubmittedByUser.userId, {
          id_verified: true,
          email_verified: true
        }).then(() => {
          nav(ALL_ROUTES.login, {
            state: {
              successMessage:
                "Your profile has been verified successfully. Please login."
            }
          })
        })
      } else {
        console.log("[VOUCHED] (onDone) VERIFICATION FAILED!")
        setFailureMessage([
            `We could not verify your identity successfully.`,
            `One of the following could be a reason:`,
            `1) The information that you have entered does not match with the documents that you have uploaded.`,
            `2) The verification flow was not completed.`,
            `Please make sure to follow the instructions and complete all the steps of the process.`,
            `We request you to start over with the verification.`
          ]
        )
      }
    },

    // callback executed after attempt to find camera device
    onCamera: ({ hasCamera, hasPermission }) => {
      console.log("[VOUCHED] onCamera - attempted to find camera")
    },

    // callback when there are changes to the Camera DOM element
    onCameraEvent: cameraEvent => {
      console.log("[VOUCHED] (onCameraEvent) camera DOM element updated")
    },

    // callback when a reverification job is complete
    onReverify: job => {
      console.log("[VOUCHED] (onReverify) reverification complete")
    },

    // callback when a survey is submitted, (per customer basis)
    onSurveyDone: job => {
      console.log("[VOUCHED] (onSurveyDone) survey done")
    },

    // callback when user confirms data and photos
    onConfirm: userConfirmEvent => {
      console.log("[VOUCHED] (onConfirm) user confirmation")
    },

    // theme
    theme: {
      name: "avant",

      iconLabelColor: "#cb9f47",
      bgColor: "#efefef",
      baseColor: "#cb9f47",
      font: "Roboto",
      handoffLinkColor: "#cb9f47",

      // logo: { src: Logo, style: { 'max-height': 80 } },
      // navigationActiveText: "",
      // iconColor: "#F00000",
      // iconBackground: "#0F0000",
      // fontColor: "#000F00",
      // navigationActiveBackground: "#00000F",
      // navigationDisabledBackground: "#D00000",
      // navigationDisabledText: "#0D0000",
      // secondaryButtonColor: "#00D000",
      // progressIndicatorTextColor: "#0000D0"
    }
  }
  useEffect(() => {
    loadVouched(config)
    // return () => {
    //   removeVouchedScript()
    // }
  }, [])

  return (
    <>
      <div id="vouched-element" style={{ height: "100%" }}></div>
      <FailurePopup
        failureMessage={failureMessage}
        show={!!failureMessage}
        onHide={() => {
          setFailureMessage(null)
          const user = JSON.parse(localStorage.getItem("user"))
          const token = localStorage.getItem("token")
          if (user.id && token) {
            nav("/verify-user", {
              state: {
                id: user.id,
                name: user.name,
                token: token
              }
            })
          } else {
            localStorage.clear()
            nav(ALL_ROUTES.login)
          }
        }}
      />
    </>
  )
}
