import Card from "react-bootstrap/Card"
import { assets } from "../../utils"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import "./product.scss"

export function BestSeller({
  id,
  product,
  flavour,
  img,
  productskuId,
  favourite,
  loading,
  onClickFavourite = () => {},
  onClickDeleteFavourite = () => {}
}) {
  const nav = useNavigate()
  const { name, brand, price } = product
  return (
    <div
      className="product best-sellers"
      onClick={() => {
        if (id) {
          const url = {}
          url.pathname = "/home/product-details/"
          url.search = `?id=${id}`
          nav(url)
        }
      }}
    >
      <div className="card_header text-center mb-2">
        <img src={img} alt="" />
      </div>
      <Card.Text className="desc text-center">{`${brand?.name} ${name}`}</Card.Text>
      <Card.Text className="name">{flavour}</Card.Text>
      <Card.Text className="price">{`$${Number(price).toFixed(2)}`}</Card.Text>
    </div>
  )
}
