import React, { useEffect, useState } from "react"
import { Button, Container, Row, Col, Form } from "react-bootstrap"
import { assets } from "../../utils"
import { ButtonGolden } from "../ButtonGolden"
import { Loader } from "../Loader"
import "./FiltersBottomDrawer.scss"

export const FiltersBottomDrawer = ({
  title = "",
  type = "",
  subTitle = "",
  radioOptions = [],
  titleSecondary = "",
  checkOptions = [],
  onChange = () => {},
  onClickCheckOption = () => {},
  onHide = () => {},
  onApply = () => {},
  reset = () => {},
  loading = false
}) => {
  return (
    <>
      <div className="FiltersBottomDrawer text-center">
        <p className="mb-3">{title}</p>
        {subTitle && <p className="mb-3">{subTitle}</p>}
        <Row className="radioOptions">
          {radioOptions.map(({ label, checked, id }) => (
            <>
              <Col xs="10" className="text-start">
                <p className={`filter-label ${checked ? "active" : ""}`}>
                  {label}
                </p>
              </Col>
              <Col xs="2" className="text-end">
                <Form.Check
                  type="radio"
                  id={`default-radio`}
                  checked={checked}
                  onChange={() => onChange(label, id)}
                />
              </Col>
            </>
          ))}
        </Row>
        <Row>
          <Col>
            <p className="mb-3">{titleSecondary}</p>
          </Col>
        </Row>
        {checkOptions.length > 0 && (
          <Row className="checkOptions">
            {loading ? (
              <Loader loading={loading} />
            ) : (
              checkOptions.map(({ label, checked, id }) => (
                <>
                  <Col xs="10" className="text-start">
                    <p className={`filter-label ${checked ? "active" : ""}`}>
                      {label}
                    </p>
                  </Col>
                  <Col xs="2">
                    <Form.Check
                      type="checkbox"
                      id={`default-check`}
                      label={``}
                      checked={checked}
                      onClick={() => onClickCheckOption(label, id)}
                    />
                  </Col>
                </>
              ))
            )}
          </Row>
        )}
        <Row>
          <Col xs="6">
            <ButtonGolden label="Reset" onClick={reset} />
          </Col>
          <Col xs="6">
            <ButtonGolden label="Apply" onClick={() => onApply(type)} />
          </Col>
          <Col xs="6">
            <Button className="close-icon-btn" onClick={onHide}>
              <img src={assets.backArrow} />
            </Button>
          </Col>
        </Row>
      </div>
      <div id="overlay"></div>
    </>
  )
}
