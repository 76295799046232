import "./Loader.scss"

export function Loader({ loading }) {
  return (
    <div className="loader-wrapper">
      {loading && (
        <p className="text-center" style={{ fontSize: "12px" }}>
          Loading...
        </p>
      )}
    </div>
  )
}
