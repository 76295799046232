import React from "react"
import { ItemStore } from "../Cart/ItemStore"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { Loader } from "../../components/Loader"
import { assets, formatAMPM, formatDateVouched } from "../../utils"

export const CompletedOrders = ({
  isLoading,
  orderHistory,
  markOrderFavourite,
  setOrderHistory,
  orderAgain
}) => {
  const nav = useNavigate()
  const [show, setShow] = React.useState(false)
  const onClickOrder = id => {
    nav(`/home/my-orders/${id}`)
  }
  return (
    <Row className="completed-order-list">
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        orderHistory?.map(
          ({
            id,
            is_favorite,
            order_status,
            total_price,
            store,
            store_logo,
            store_name,
            updated_on
          }) => (
            <Col
              key={id}
              xs="12"
              className="column position-relative pe-3"
              onClick={() => onClickOrder(id)}
              style={{ cursor: "pointer" }}
            >
              {/* <ItemStore
                store={{ store_name }}
                status={order_status}
                updated_on={updated_on}
                total_price={total_price}
              /> */}
              <div className="completed-order-wrapper">
                <div className="location-image">
                  <img src={assets.mapIcon}/>
                </div>
                <div className="order-details">
                  <p className="store-name">{store_name}</p>
                  <p className="date">{`${formatDateVouched(updated_on)} - ${formatAMPM(new Date(updated_on))}`}</p>
                  <p className="order-value">{`$ ${total_price}`}</p>
                </div>
              </div>
              <p
                style={{
                  position: "absolute",
                  top: '20px',
                  right: '4rem',
                  whiteSpace: "nowrap",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "12px",
                  textAlign: "right",
                  color: "#CB9F47",
                  margin:0
                }}
                className="order-again-action"
                onClick={e => {
                  e.stopPropagation()
                  orderAgain(id).then(() => setShow(true))
                }}
              >
                Order again
              </p>
              <Button
                className="position-absolute"
                style={{ top: "0.5rem", right: "1rem" }}
                onClick={e => {
                  if (!is_favorite) {
                    e.stopPropagation()
                    markOrderFavourite(id, { is_favorite: true }).then(() => {
                      setOrderHistory(prevOrders => {
                        const updated = [...prevOrders]
                        const index = updated.findIndex(item => item.id == id)
                        updated[index].is_favorite = true
                        return updated
                      })
                    })
                  }
                }}
              >
                <img
                  src={is_favorite ? assets.heartFilledIcon : assets.heartBlack}
                />
              </Button>
            </Col>
          )
        )
      )}
      {show && (
        <MyVerticallyCenteredModal show={true} onHide={() => setShow(false)} />
      )}
    </Row>
  )
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Row className="flex-column pt-3 text-center">
          <Col>
            <p style={{ fontSize: "14px" }}>Items added to cart!</p>
            <Link to="/home/cart">View Cart</Link>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
