import { API } from "../api";
import { APPCONFIG } from "../app-config";

export default async function OneSignalPush() {
    const OneSignal = window.OneSignal || [];
    const initConfig = {
        appId: APPCONFIG.oneSignalAppId,
        allowLocalhostAsSecureOrigin: true,
        // requiresUserPrivacyConsent: true
    };
    OneSignal.push(function () {
        OneSignal.SERVICE_WORKER_PARAM = { scope: '/push/onesignal/' };
        OneSignal.SERVICE_WORKER_PATH = 'push/onesignal/OneSignalSDKWorker.js'
        OneSignal.SERVICE_WORKER_UPDATER_PATH = 'push/onesignal/OneSignalSDKWorker.js'
        OneSignal.init(initConfig);
    });
    OneSignal.push(function () {
        OneSignal.showSlidedownPrompt();
    });
    const playerId = await OneSignal.getUserId()
    const payload = {}
    payload.one_device_token = playerId
    if (payload.one_device_token) {
        new Promise((resolve, reject) => {
            API.updateDeviceId(payload).then(res => {
                console.log('device id updated successfully')
                resolve(res)
            })
        })
    }
}
