import React from "react"
import { Button, Container, Col, Row } from "react-bootstrap"
import { assets } from "../../utils"
import { ButtonGolden } from "../ButtonGolden"
import "./MyLocationDetails.scss"

export const MyLocationDetails = ({
  placeName = "",
  store = { address: "", distance: 0, logo: null, name: "", phone_number: "" },
  onClickHandle = () => {},
  setConfirmChange = () => {},
  showStore = false
}) => {
  return (
    <>
      <Container className="confirm-address-wrapperXX pb-4" id="myPopup">
        {showStore ? (
          <Row className="p-3">
            <Col xs="2" className="mb-3">
              <img
                src={store.logo ? store.logo : assets.marker}
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  backgroundSize: "cover"
                }}
              />
            </Col>
            <Col xs="10">
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "14px"
                }}
              >
                {store.name}
              </p>
            </Col>
            <Col xs="12" className="mb-2">
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#CB9F47"
                }}
              >{`${Number(store.distance).toFixed(
                1
              )} km from your location`}</p>
            </Col>
            <Col xs="12" className="mb-2">
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "16px"
                }}
              >
                {store.phone_number}
              </p>
            </Col>
            <Col xs="12" className="mb-2">
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "16px"
                }}
              >
                {store.address}
              </p>
            </Col>
          </Row>
        ) : (
          <>
            <p className="my-location-details fw-bold fs-5 py-3 text-center">
              My location details
            </p>
            <Container className="">
              <div className="current-location-wrapper d-flex align-items-center justify-content-between mb-3">
                <Col xs="1" className="marker">
                  <img src={assets.marker} />
                </Col>
                <Col xs="7" className="current-location">
                  <p>{placeName}</p>
                </Col>
                <Col xs="4" className="change-location-btn text-end">
                  <Button
                    onClick={() => setConfirmChange(false)}
                    className="rounded-pill px-4"
                    variant="secondary"
                  >
                    Change
                  </Button>
                </Col>
              </div>
              <ButtonGolden label="Done" onClick={onClickHandle} />
            </Container>
          </>
        )}
      </Container>
      <div id="overlay"></div>
    </>
  )
}
