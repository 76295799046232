import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Col, Container, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import InputField from "../../components/InputField"
import { formatDateVouched, validation } from "../../utils"
import { ButtonGolden } from "../../components/ButtonGolden"
import { useAuthContext } from "../../contexts/AuthContext"
import { removeVouchedScript } from "../../utils/vouchedScript"

import "./VerifyUser.scss"

export function VerifyUser() {
  const nav = useNavigate()
  const { state } = useLocation()
  const { isLoading, updateUserProfile, setVerication } = useAuthContext()
  const { control, trigger, setValue, handleSubmit } = useForm()
  const [selectedDob, setSelectedDob] = useState(null)

  const dobYearsBefore = 100
  let dobSelectedDate
  if (!!selectedDob) {
    dobSelectedDate = new Date(selectedDob)
  } else {
    dobSelectedDate = new Date()
    dobSelectedDate.setFullYear(new Date().getFullYear() - 20)
  }

  const onSubmit = async vals => {
    const payload = {}
    const { firstName, lastName, date_of_birth } = vals
    payload.name = `${firstName} ${lastName}`
    payload.date_of_birth = date_of_birth
    payload.email_verified = true
    updateUserProfile(state.id, payload).then(() => {
      setVerication({
        userId: state.id,
        firstName: firstName.toUpperCase(),
        lastName: lastName.toUpperCase(),
        birthDate: formatDateVouched(date_of_birth)
      })
      nav("/id-verification", { state: { verificationPayload: vals } })
    })
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (state?.name || user) {
      setValue("firstName", `${state.name || user.name || ''}`.split(" ")[0])
      setValue("lastName", `${state.name || user.name || ''}`.split(" ")[1])
    }
    removeVouchedScript()
  }, [state])

  return (
    <Container className="pt-5 verify-user">
      <Row className="mb-3">
        <Col xs="12" className="text-center">
          <h1>Verify your name and date of birth</h1>
        </Col>
        <Col xs="12" className="text-center">
          <p className="make-sure-text">
            Make sure the information you enter is exactly as your government ID
          </p>
        </Col>
      </Row>
      <form className={"signup"} onSubmit={handleSubmit(onSubmit)}>
        <Row className="flex-column justify-content-center align-items-center">
          <Col xs={12} lg="4" className="mb-3">
            <InputField
              label="First Name"
              name="firstName"
              placeholder=""
              rules={{
                required: "First Name required!",
                validate: { ...validation.name }
              }}
              compType=""
              control={control}
              trigger={trigger}
              // className="applicationScreenInput"
            />
          </Col>
          <Col xs={12} lg="4" className="mb-3">
            <InputField
              label="Last Name"
              name="lastName"
              placeholder=""
              rules={{
                required: "Last Name required!",
                validate: { ...validation.name }
              }}
              compType=""
              control={control}
              trigger={trigger}
              // className="applicationScreenInput"
            />
          </Col>
          <Col xs={12} lg="4" className="mb-3">
            <InputField
              label="Date of birth"
              name="date_of_birth"
              type="date"
              rules={{
                required: "Date of birth required!",
                validate: { ...validation.dob }
              }}
              control={control}
              trigger={trigger}
              dobYearsBefore={dobYearsBefore}
              datepickerSelectedDate={dobSelectedDate}
              maxDate={new Date()}
            />
          </Col>

          <Col xs={12} lg="4">
            <ButtonGolden label="Next" disabled={isLoading} />
          </Col>
        </Row>
      </form>
    </Container>
  )
}
