import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Col, Row, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import InputField from "../../components/InputField"
import { InputPassword } from "../../components/InputPassword/InputPassword"
import { validation } from "../../utils"
import { CommonAuthScreen } from "../CommonAuthScreen"
import { ButtonGolden } from "../../components/ButtonGolden"
import { useAuthContext } from "../../contexts/AuthContext"
import { validatePassword } from "../../utils/Validation"
import { Tnc } from "../Tnc"
import { ALL_ROUTES } from "../../app-config"

import "./signup.scss"

export function Signup() {
  const nav = useNavigate()
  const {
    isLoading,
    showTnc,
    showPp,
    setShowTnc,
    setShowPp,
    tncRead,
    ppRead,
    setTncRead,
    setPpRead,
    getTnc,
    getPrivacyPolicy,
    userSignup
  } = useAuthContext()
  const [acceptTnC, setAcceptTnC] = useState(false)

  const {
    control,
    register,
    trigger,
    watch,
    formState: { errors: formErrors, dirtyFields },
    handleSubmit
  } = useForm()
  const [errors, setErrors] = useState({
    password: {
      error: false,
      message: ""
    },
    confirm_password: {
      error: false,
      message: ""
    }
  })
  const password = watch("password")
  const confirmPassword = watch("confirm_password")

  const onSubmit = async vals => {
    userSignup(vals).then(() =>
      nav("/verify-email", {
        state: { email: vals.email }
      })
    )
  }

  const onChangeAcceptTnC = ({ target }) => {
    const { checked } = target
    setAcceptTnC(checked)
  }

  useEffect(() => {
    if (password) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: { message: validatePassword(password) }
      }))
    }
    if (confirmPassword) {
      setErrors(prevErrors => ({
        ...prevErrors,
        confirm_password: { message: validatePassword(password) }
      }))
    }

    if (confirmPassword) {
      if (password) {
        if (confirmPassword !== password) {
          setErrors(prevErrors => ({
            ...prevErrors,
            confirm_password: { message: "Passwords do not match" }
          }))
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            confirm_password: { message: "" }
          }))
        }
      }
    }
  }, [password, confirmPassword])

  useEffect(() => {
    if (formErrors?.password?.message) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: { message: "Password is required" }
      }))
    }
    if (formErrors?.confirm_password?.message) {
      setErrors(prevErrors => ({
        ...prevErrors,
        confirm_password: { message: "Confirm Password is required" }
      }))
    }
  }, [handleSubmit, formErrors, dirtyFields])

  return (
    <CommonAuthScreen
      title={
        <>
          Welcome to <br /> Vape Smoke Universe
        </>
      }
    >
      <form className={"signup"} onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col xs={12}>
            <InputField
              label="Name"
              name="name"
              placeholder=""
              rules={{
                required: "Name required!",
                validate: { ...validation.name }
              }}
              compType=""
              control={control}
              trigger={trigger}
              className="applicationScreenInput"
            />
          </Col>
          <Col xs={12}>
            <InputField
              label="Email"
              name="email"
              placeholder=""
              rules={{
                required: "Email required!",
                validate: { ...validation.email }
              }}
              compType=""
              control={control}
              trigger={trigger}
              className="applicationScreenInput"
            />
          </Col>
          <Col xs={12}>
            <InputPassword
              label="Password"
              register={register}
              control={control}
              trigger={trigger}
              rules={{
                required: "Password required!",
                validate: { ...validation.password }
              }}
              name="password"
              placeholder=""
              error={errors["password"]}
              className="applicationScreenInput"
            />
          </Col>
          <Col xs={12}>
            <InputPassword
              label="Confirm Password"
              register={register}
              control={control}
              trigger={trigger}
              rules={{
                required: "Confirm Password required!",
                validate: (value) => value === watch("password") || "Passwords do not match"
              }}
              name="confirm_password"
              placeholder=""
              error={errors["confirm_password"]}
              className="applicationScreenInput"
            />
          </Col>
          <Col xs={12} className="text-center">
            <p>
              Already have an account? <Link to={ALL_ROUTES.login}>Log in</Link>
            </p>
          </Col>
          <Col xs={12} className="text-start mb-4">
            <div className="agree d-flex align-items-baseline position-relative">
              <Form.Check
                inline
                label=""
                name="group1"
                type={"checkbox"}
                id={`inline-${"checkbox"}-terms-and-conditions`}
                className="me-0"
                onChange={onChangeAcceptTnC}
              />
              <div className="position-absolute tnc-wrapper">
                <p className="text-nowrap mb-1">
                  By continue you agree with VSU
                  <Link
                    className={`${tncRead ? "visited" : ""}`}
                    // to="/tnc"
                    onClick={() => {
                      setShowTnc(true)
                      // setTncRead(true)
                    }}
                  >
                    Terms & Conditions
                  </Link>
                </p>
                <p>
                  and
                  <Link
                    className={`${ppRead ? "visited" : ""}`}
                    // to="/privacy-policy"
                    onClick={() => {
                      setShowPp(true)
                      // setPpRead(true)
                    }}
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <ButtonGolden
              label="Sign up"
              disabled={
                password !== confirmPassword ||
                isLoading ||
                !acceptTnC
              }
            />
          </Col>
        </Row>
      </form>
      {showTnc && (
        <Tnc
          show={showTnc}
          setShow={setShowTnc}
          onClickAgree={setTncRead}
          title="Terms & Conditions"
          isTnc={true}
          loadData={getTnc}
        />
      )}
      {showPp && (
        <Tnc
          show={showPp}
          setShow={setShowPp}
          onClickAgree={setPpRead}
          title="Privacy Policy"
          isTnc={true}
          loadData={getPrivacyPolicy}
        />
      )}
    </CommonAuthScreen>
  )
}
