import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"

const OrderContext = createContext()

const OrderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [orderHistory, setOrderHistory] = useState([])
  const [order, setOrder] = useState({})
  const [selectedOrderKey, setSelectedOrderKey] = useState('active')

  const getOrders = config => {
    setIsLoading(true)
    API.getOrderHistory(null, config)
      .then(response => {
        let sortedResponse = response.data.payload?.results?.sort((a,b)=>new Date(a.created_on).getTime() < new Date(b.created_on).getTime()?1:-1)
        setOrderHistory(sortedResponse)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getOrder = (id, config) => {
    setIsLoading(true)
    API.getOrderHistory(id, config)
      .then(response => setOrder(response.data.payload))
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const markOrderFavourite = (id, payload) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.markOrderFavourite(id, payload)
        .then(response => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const orderAgain = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.orderAgain(id)
        .then(response => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }
  
  const curbsideNotify = id => {
    // setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.curbsideNotify(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => {})
    })
  }

  return (
    <OrderContext.Provider
      value={{
        isLoading,
        orderHistory,
        getOrders,
        order,
        getOrder,
        setOrderHistory,
        markOrderFavourite,
        orderAgain,
        curbsideNotify,
        setSelectedOrderKey,
        selectedOrderKey
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

export const useOrderContext = () => useContext(OrderContext)

export default OrderProvider
