import { toast } from "react-toastify"
import { ALL_ROUTES } from "../app-config"
import Request from "./axiosInstance"

const tokenInterceptor = Request.interceptors.request.use(
  req => {
    const token = localStorage.getItem("token")
    if (!!token) {
      req.headers["Authorization"] = `Token ${token}`
    }
    return req
  },
  null,
  { synchronous: true }
)

const catchErrors = err => {
  if ([400, 401, 403, 500].find(e => e == err?.response?.status)) {
    createCustomErrorMessage(err)
    // if any response is a 401 or 403, redirect to login
    if ([401].find(e => e == err?.response?.status)) {
      window.location = ALL_ROUTES.login
    }
  }
  throw err
}

window.addEventListener("storage", () => {
  if (localStorage.getItem("token")) {
    Request.interceptors.request.eject(tokenInterceptor)
    Request.interceptors.request.use(tokenInterceptor)
  }
})

export const get = (url, config) => {
  return Request.get(url, config).catch(catchErrors)
}

export const post = (url, data, config) => {
  return Request.post(url, data, config).catch(catchErrors)
}

export const put = (url, config) => {
  return Request.put(url, config).catch(catchErrors)
}

export const patch = (url, data, config) => {
  return Request.patch(url, data, config).catch(catchErrors)
}

export const del = (url, data, config) => {
  return Request.delete(url, data, config).catch(catchErrors)
}

export const createCustomErrorMessage = (err) => {
  const responseStatus = err?.response?.status
  const responseErrorMessage = err?.response?.data?.error || 'An unexpected server error has occurred.'
  toast.error(responseErrorMessage, { autoClose: false })
  if (responseStatus === 401 && localStorage.getItem("token")) {
    localStorage.removeItem("token")
    window.location = ALL_ROUTES.login
  }
  // Deprecating `customErrorCallback`, using toast messages instead
  // window?.customErrorCallback?.(err?.response?.data?.error, err?.response?.status)

}
