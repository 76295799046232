import React from "react"
import { Col, NavDropdown, Row } from "react-bootstrap"
import { assets } from "../../utils"
import "./MyCurrentLocation.scss"

export const MyCurrentLocation = ({ placeName, setCurrentLocation }) => {
  return (
    <Row className="MyCurrentLocation">
      <Col md={4} lg={3} xs={10}>
        <NavDropdown
          title={
            <>
              <div className="arrow mt-1">
                <img src={assets.downArrow} />
              </div>
              <h6 className="my-location-title">My Location</h6>
              <p className="my-current-location">{(placeName)? placeName : "Select an Address"}</p>
            </>
          }
          as={"h6"}
          id="collasible-nav-dropdown"
        >
          <NavDropdown.Item onClick={() => setCurrentLocation()}>
            <div>
              <span className="location-selected">{placeName}</span>
            </div>
          </NavDropdown.Item>
          <NavDropdown.Divider />

          <NavDropdown.Item
            onClick={() => setCurrentLocation()}
            className="d-flex align-items-center justify-content-between"
          >
            <div> Use current location</div>
            <div>
              <img src={assets.forwardArrow} />
            </div>
          </NavDropdown.Item>
          <NavDropdown.Divider />

          <NavDropdown.Item
            href="/home/search-address"
            className="d-flex align-items-center justify-content-between"
          >
            <div>Change address</div>
            <div>
              <img src={assets.forwardArrow} />
            </div>
          </NavDropdown.Item>
        </NavDropdown>
      </Col>
    </Row>
  )
}
