import React, { useEffect, useState } from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { Header } from "./Header"
import { useOrderContext } from "../../contexts/OrderContext"
import { useNavigate } from "react-router-dom"
import { CompletedOrders } from "./CompletedOrders"
import { Loader } from "../../components/Loader"
import { ItemStore } from "../Cart/ItemStore"
import "./MyOrders.scss"
import OrdersList from "./OrderList"
import { useMapContext } from "../../contexts/MapContext"

export const MyOrders = () => {
  const nav = useNavigate()
  const {
    isLoading,
    orderHistory,
    getOrders,
    markOrderFavourite,
    setOrderHistory,
    orderAgain,
    selectedOrderKey,
    setSelectedOrderKey
  } = useOrderContext()
  // const [key, setKey] = useState("active")
  const { currentLocation, getCurrentLocation } =
    useMapContext()

  useEffect(() => {
    const config = {
      params: {}, paramsSerializer: {
        indexes: null
      }
    }
    if (selectedOrderKey === "active") {
      config.params.status = ["PD", "RP"]
    } else if (selectedOrderKey === "completed") {
      config.params.status = "CO"
    } else if (selectedOrderKey === "favorite") {
      config.params.status = "CO"
      config.params.is_favorite = true
    }

    getOrders(config)
  }, [selectedOrderKey])

  useEffect(() => {
    if (!currentLocation.latitude && !currentLocation.longitude) {
      getCurrentLocation()
    }
  }, [])

  return (
    <Container className="my-orders">
      <Header />
      <Tabs
        id="uncontrolled-tab-example"
        activeKey={selectedOrderKey}
        onSelect={k => setSelectedOrderKey(k)}
        className="mb-3"
        fill
        unmountOnExit={true}
      >
        <Tab eventKey="active" title="Active">
          <Row>
            {isLoading ? (
              <Loader loading={isLoading} />
            ) : (
              orderHistory?.map(
                ({ id, store_name, store_lat, store_lng, suborder_id, order_items_count, pickup_type }) => (
                  <Col
                    xs="12"
                    className="cart-item-wrapper ps-3 pe-4"
                    key={id}
                    onClick={() => nav(`/home/my-orders/${id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <ItemStore
                      store={{ store_name }}
                      status="Completed"
                      updated_on={updated_on}
                      order_value={order_value}
                    /> */}
                    <OrdersList
                      orderType={'active'}
                      exactOrderId={id}
                      orderId={suborder_id}
                      storeName={store_name}
                      lat={store_lat}
                      lng={store_lng}
                      isCurbside={pickup_type === 'Curbside'}
                      // isCurbside={true}
                      productCount={order_items_count}
                    />
                  </Col>
                )
              )
            )}
          </Row>
        </Tab>
        <Tab eventKey="completed" title="Completed">
          <CompletedOrders
            isLoading={isLoading}
            orderHistory={orderHistory}
            markOrderFavourite={markOrderFavourite}
            setOrderHistory={setOrderHistory}
            orderAgain={orderAgain}
          />
        </Tab>
        <Tab eventKey="favorite" title="Favorite">
          <CompletedOrders isLoading={isLoading} orderHistory={orderHistory} />
        </Tab>
      </Tabs>
      <Row></Row>
    </Container>
  )
}
