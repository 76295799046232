import { useState, useEffect, useCallback, useMemo } from "react"
import Map, { Marker } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { MAPBOX_TOKEN, assets } from "../../utils"
import { useStoreContext } from "../../contexts/StoreContext"
import { useMapContext } from "../../contexts/MapContext"
import Pin from "./Pin"
import { MyLocationDetails } from "../MyLocationDetails/MyLocationDetails"
import { Loader } from "../Loader"
// added the following 6 lines.
import mapboxgl from 'mapbox-gl';
import { useNavigate } from "react-router-dom"

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export function MyMap() {
  const { isLoading, stores } = useStoreContext()
  const nav = useNavigate()
  const { currentLocation } = useMapContext()
  const [viewport, setViewport] = useState({})
  const [events, logEvents] = useState({})
  const [popupInfo, setPopupInfo] = useState(null)
  const [showPopup, setShowPopup] = useState(false)

  const pins = useMemo(
    () =>
      stores.map((store, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={store.lng}
          latitude={store.lat}
          anchor="bottom"
          onClick={e => {
            e.originalEvent.stopPropagation()
            setPopupInfo(store)
            setShowPopup(true)
          }}
        >
          <Pin />
        </Marker>
      )),
    [stores]
  )

  const onMarkerDragStart = useCallback(event => {
    logEvents(_events => ({ ..._events, onDragStart: event.lngLat }))
  }, [])

  const onMarkerDrag = useCallback(event => {
    logEvents(_events => ({ ..._events, onDrag: event.lngLat }))
  }, [])

  const onMarkerDragEnd = useCallback(event => {
    logEvents(_events => ({ ..._events, onDragEnd: event.lngLat }))
  }, [])

  useEffect(() => {
    const { onDragEnd } = events
    if (onDragEnd) {
      console.log("onDragEnd", onDragEnd)
    }
  }, [events])

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: currentLocation.latitude,
      longitude: currentLocation.longitude,
      zoom: 12
    })
  }, [currentLocation])

  useEffect(() => {
    const myMapWrapper = document.getElementById("myMapWrapper")
    const myPopup = document.getElementById("myPopup")
    if (myMapWrapper) {
      myMapWrapper.addEventListener("click", e => {
        setShowPopup(false)
        e.stopPropagation()
      })
    }

    if (myPopup) {
      myPopup.addEventListener("click", e => {
        e.stopPropagation()
        nav(`/home/store/${popupInfo.id}`)
      })
    }
  }, [showPopup, popupInfo])

  return (
    <div id="myMapWrapper">
      {viewport.latitude && viewport.longitude && (
        <div>
          <Map
            mapboxAccessToken={MAPBOX_TOKEN}
            initialViewState={viewport}
            style={{ width: "100%", height: "100vh" }}
            mapStyle="mapbox://styles/valerydeleon/clf7r7psd000t01mqdievb12x"
          >
            {pins}
            <Marker
              longitude={viewport.longitude}
              latitude={viewport.latitude}
              scrollZoom={false}
              boxZoom={false}
              doubleClickZoom={false}
              draggable
              onDragStart={onMarkerDragStart}
              onDrag={onMarkerDrag}
              onDragEnd={onMarkerDragEnd}
            >
              <img src={assets.currentLocationMarker} />
            </Marker>
          </Map>
        </div>
      )}
      {showPopup && (
          <MyLocationDetails
            store={popupInfo}
            onClickHandle={() => {}}
            setConfirmChange={() => {}}
            showStore={true}
          />
      )}
      <Loader loading={isLoading} />
    </div>
  )
}
