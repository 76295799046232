import React, { useState } from 'react'
import { assets } from '../../utils'
import { MyMap } from '../../components/MyMap'
import { SpecificStoreMap } from '../../components/MyMap/specificStoreMap'
import { useOrderContext } from '../../contexts/OrderContext'

function OrdersList({ orderType, exactOrderId, orderId, storeName, productCount, lat, lng, isCurbside }) {
  const { curbsideNotify } = useOrderContext()
  const [showNotification, setShowNotification] = useState('')
  const notifyCurbside = (e) => {
    e.stopPropagation()
    curbsideNotify(exactOrderId).then(res=>{
      console.log('***Store notified***')
      const message = res?.payload?.error[0] || 'Store Notified'
      displayNotification(message)
    }).catch(error=>{
      let message = 'Something went Wrong'
      if(error.response.data.status_code===429){
        message = 'Already Notified Store Admin, Re-notify in some time'
      }
      displayNotification(message)
    })
  }

  const displayNotification = (message) => {
    setShowNotification(message)
    setTimeout(() => {
      setShowNotification('')
    }, [5000])
  }

  const openMapforLocation = (e) => {
    e.stopPropagation()
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,'_blank')
  }
  return (
    <div className='order-list-wrapper'>
      <div className='order-id'>
        <img src={assets.orderIcon} />
        <span>{`Your Order: ${exactOrderId}`}</span>
      </div>
      <div className='store-name'>
        <img src={assets.orderPin} />
        <span>{`${storeName}`}</span>
      </div>
      <div className='product-count'>
        <span>
          <img src={assets.orderVape} />
          <span>Products: {productCount}</span>
        </span>
        <span>
          <img className='product-details' src={assets.backArrow}/>
        </span>
      </div>
      <div className='map-view' onClick={openMapforLocation}>
        <SpecificStoreMap lat={lat} lng={lng}/>
      </div>
      <div className='order-eta'>
        <span>
        <img src={assets.clock} />
        &nbsp;
        Your order will be ready in: <strong>10 min</strong>
        </span>
        {isCurbside && <span className='curbside-pickup'>
          <button className='curbside-button' onClick={e=>notifyCurbside(e)}>Curbside</button>
      </span>}
      </div>
      {!!showNotification.length && <div className='notification-box'>{showNotification}</div>}
    </div>
  )
}

export default React.memo(OrdersList)
