export const APPCONFIG = {
  oneSignalAppId:
    window.location.href.indexOf("staging") > -1 ||
    window.location.href.indexOf("localhost") > -1
      ? "4fb6a2d6-bfe1-492d-8c6b-9ffaf0348d2a"
      : "4fb6a2d6-bfe1-492d-8c6b-9ffaf0348d2a",
  
  dashboardPrefix: "home"
}

export const ALL_ROUTES = {
  login: "/",
  signup: "/signup",
  resetPassword: "/reset-password",
  verifyEmail: "/verify-email",
  checkEmail: "/check-email",
  otpVerification: "/otp-verification",
  forgotPasswordVerify: "/forgot-password-verify",
  setNewPassword: "/set-new-password",
  onboarding: "/onboarding",
  verifyUser: "/verify-user",
  idVerification: "/id-verification",

  HOME: {
    stores: `/${APPCONFIG.dashboardPrefix}/stores`,
    storeDetails: `/${APPCONFIG.dashboardPrefix}/store/:storeId`,
    searchAddress: `/${APPCONFIG.dashboardPrefix}/search-address`,
    myMap: `/${APPCONFIG.dashboardPrefix}/myMap`,
    productDetails: `/${APPCONFIG.dashboardPrefix}/product-details`,
    cart: `/${APPCONFIG.dashboardPrefix}/cart`,
    myOrders: `/${APPCONFIG.dashboardPrefix}/my-orders`,
    feedback: `/${APPCONFIG.dashboardPrefix}/feedback`,
    myProducts: `/${APPCONFIG.dashboardPrefix}/my-products`,
    myOrderDetails: `/${APPCONFIG.dashboardPrefix}/my-orders/:id`,
    paymentSuccess: `/${APPCONFIG.dashboardPrefix}/payment-success/:uid/:id/:oid`,
    payment: `/${APPCONFIG.dashboardPrefix}/payment`
  }
}
