import React, { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../components/Loader"
import { useAuthContext } from "../../contexts/AuthContext"
import { assets } from "../../utils"
import "./Tnc.scss"

export const Tnc = ({
  show,
  setShow = () => {},
  onClickAgree = () => {},
  title,
  loadData
}) => {
  const nav = useNavigate()
  const { isLoading, content, setContent } = useAuthContext()

  const values = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"]
  const [fullscreen, setFullscreen] = useState(true)

  const stringToHTML = data => {
    const parser = new DOMParser()
    const parsedDocument = parser.parseFromString(data, "text/html")
    const content = parsedDocument.getElementById("content")
    return content
  }

  useEffect(() => {
    if (content) {
      const result = stringToHTML(content)
      const targetBody = document.getElementById("contentWrapper")
      if (targetBody) {
        targetBody.appendChild(result)
      }
    }
  }, [content])

  useEffect(() => {
    loadData()

    return () => setContent("")
  }, [])

  return (
    <>
      <Modal
        className="tnc-pp-wrapper"
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Col xs="2">
            <img src={assets.backArrow} onClick={() => setShow(false)} />
          </Col>
          <Col xs="8" className="text-center">
            <span className="modal-title">{title}</span>
          </Col>
          <Col xs="2"></Col>

          {/* <Modal.Title>{title}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div id="contentWrapper" className="pt-3"></div>
          <Loader loading={isLoading} />
        </Modal.Body>
        <Modal.Footer>
          <Col xs="12" className="text-center">
            <Button
              variant="dark"
              type="submit"
              style={{
                width: "calc(100% - 2rem)",
                maxWidth: "22rem"
              }}
              onClick={() => {
                onClickAgree(true)
                setShow(false)
              }}
            >
              I agree
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  )

  return (
    <Container className="tnc">
      <Row>
        <Col className="header">
          <Row>
            <Col xs="2" className="text-left" onClick={() => nav("/signup")}>
              <img src={assets.backArrow} />
            </Col>
            <Col xs="8" className="text-center">
              {title}
            </Col>
          </Row>
        </Col>
        <Col xs="12" className="mb-5"></Col>

        <Col xs="12" id="contentWrapper"></Col>

        <Col xs="12"></Col>
      </Row>
      <Loader loading={isLoading} />
    </Container>
  )
}
