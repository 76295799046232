import { useState, useEffect, useCallback, useMemo } from "react"
import Map, { Marker } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { MAPBOX_TOKEN, assets } from "../../utils"
import { useStoreContext } from "../../contexts/StoreContext"
import { useMapContext } from "../../contexts/MapContext"
import Pin from "./Pin"
import { MyLocationDetails } from "../MyLocationDetails/MyLocationDetails"
import { Loader } from "../Loader"
// added the following 6 lines.
import mapboxgl from 'mapbox-gl';
import { useNavigate } from "react-router-dom"

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export function SpecificStoreMap({lat,lng}) {
  const [viewport, setViewport] = useState({})
  const { isLoading, stores } = useStoreContext()

  const pins = useMemo(
    () =>
        <Marker
          key={`marker-${lat}`}
          longitude={lng}
          latitude={lat}
          anchor="bottom"
        >
          <Pin />
        </Marker>
    ,[]
  )

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: lat,
      longitude: lng,
      zoom: 13
    })
  }, [lat,lng])

  return (
    <div id="myMapWrapper">
      {viewport.latitude && viewport.longitude && (
        <div>
          <Map
            mapboxAccessToken={MAPBOX_TOKEN}
            initialViewState={viewport}
            style={{ width: "100%", height: "200px" }}
            mapStyle="mapbox://styles/valerydeleon/clf7r7psd000t01mqdievb12x"
            boxZoom={false}
            scrollZoom={false}
          >
            {pins}
            {/* <Marker
              longitude={lng}
              latitude={lat}
              scrollZoom={false}
              boxZoom={false}
              doubleClickZoom={false}
            >
              <img src={assets.currentLocationMarker} />
            </Marker> */}
          </Map>
        </div>
      )}
      <Loader loading={isLoading} />
    </div>
  )
}
