import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

export function FailurePopup(props) {
  const { failureMessage = "", ...modalProps } = props
  const messageLines =
    typeof failureMessage === "string" ? [failureMessage] : failureMessage
  return (
    <Modal
      {...modalProps}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h3 className="m-0">Identity Verification Failed</h3>
      </Modal.Header>
      <Modal.Body>
        {messageLines.map((messageLine, i) => (
          <p className={i > 0 ? `mt-3 mb-0` : `m-0`} key={i}>
            {messageLine}
          </p>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Start Over</Button>
      </Modal.Footer>
    </Modal>
  )
}
