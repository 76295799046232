import React, { useEffect, useState } from "react"
import { Alert, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { ALL_ROUTES } from "../../app-config"

const ErrorAlert = () => {
  const nav = useNavigate()
  const [show, setShow] = useState(false)
  const [errorObject, setErrorObject] = useState()

  useEffect(() => {
    window.customErrorCallback = (errorObject, status) => {
      if (status === 401 && localStorage.getItem("token")) {
        localStorage.removeItem("token")
        nav(ALL_ROUTES.login)
        return
      }
      setShow(true)
      setErrorObject(errorObject)
    }
  }, [show, errorObject])

  return (
    <>
      {show && (
        <>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="error-alert-modal "
            aria-labelledby="example-custom-modal-styling-title"
            backdrop={true}
            backdropClassName="error-alert-backdrop"
          >
            <Alert
              variant={"danger"}
              onClose={() => setShow(false)}
              dismissible
              style={{ zIndex: 1000, left: 10, right: 10, top: 25 }}
              className={"position-absolute"}
            >
              <Alert.Heading>{"Error !"}</Alert.Heading>
              <p>{errorObject}</p>
            </Alert>
          </Modal>
        </>
      )}
    </>
  )
}

export default ErrorAlert
