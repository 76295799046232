import { Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { Store } from "../Store/Store"
import "./stores.scss"

export function Stores({ stores, isUserFlagged, markStoreAsFavorite, markStoreAsUnFavorite }) {
  const nav = useNavigate()

  return (
    <div className={`stores ${isUserFlagged ? "pointer-events" : ""}`}>
      <Row className="">
        {stores?.map(({ id, name, phone_number, address, distance, logo, is_favorite, favStoreId }) => (
          <Col xs="12" sm="6" md="6" lg="3" className="mb-4" key={id}>
            <Store
              id={id}
              name={name}
              phone={phone_number}
              address={address.replace(', United States','')}
              distance={distance}
              logo={logo}
              is_favorite={is_favorite}
              favStoreId={favStoreId}
              markStoreAsFavorite={markStoreAsFavorite}
              markStoreAsUnFavorite={markStoreAsUnFavorite}
              onClick={(id) => {
                if(isUserFlagged){return}
                nav(`/home/store/${id}`)
              }}
            />
          </Col>
        ))}
      </Row>
      {isUserFlagged && <div className="flagged-overlay"></div>}
    </div>
  )
}
