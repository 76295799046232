import React, { useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useAuthContext } from "../../contexts/AuthContext"
import InputField from "../../components/InputField"
import { InputPassword } from "../../components/InputPassword/InputPassword"
import { validation } from "../../utils"
import { CommonAuthScreen } from "../CommonAuthScreen"
import { ButtonGolden } from "../../components/ButtonGolden"
import "./login.scss"
import { toast } from "react-toastify"

export function Login() {
  const nav = useNavigate()
  const { state: locationState } = useLocation()
  const { isLoading, userLogin } = useAuthContext()
  const { control, register, trigger, formState, handleSubmit } = useForm()

  useEffect(() => {
    if(!!locationState?.successMessage) {
      toast.success(locationState?.successMessage, { autoClose: false })
    }
    if(!!locationState?.errorMessage) {
      toast.error(locationState?.errorMessage, { autoClose: false })
    }
  }, [])

  const onSubmit = vals => {
    userLogin(vals).then(response => {
      const { email_verified, id_verified } = response.user?.userprofile
      const { email } = response?.user
      if (!email_verified) {
        nav("/verify-email",{
          state: { email: email }
        })
      } else if (!id_verified) {
        nav("/verify-user", {
          state: {
            id: response.user.id,
            name: response.user.name,
            token: response.token
          }
        })
      } else if (response.user.role === "CUST") {
        localStorage.setItem("token", response.token)
        localStorage.setItem("user", JSON.stringify(response.user))
        nav("/home/stores")
      }
    })
  }

  return (
    <CommonAuthScreen
      isLogin={true}
      title={
        <>
          Welcome to <br /> Vape Smoke Universe
        </>
      }
    >
      <form className={"login"} onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col xs={12}>
            <InputField
              label="Email"
              name="email"
              placeholder=""
              rules={{
                required: "Email required!",
                validate: { ...validation.email }
              }}
              compType=""
              control={control}
              trigger={trigger}
              className="applicationScreenInput login-form"
            />
          </Col>
          <Col xs="12">
            <InputPassword
              label="Password"
              name="password"
              register={register}
              control={control}
              trigger={trigger}
              rules={{
                required: "Password required!",
                validate: { ...validation.password }
              }}
              placeholder=""
              errors={{}}
              customClass={'login-form'}
              className="applicationScreenInput"
            />
          </Col>
          <p className="m-0 text-end">
            <Link to="/reset-password">Forgot password?</Link>
          </p>

          <Col xs={12} className="text-center">
            <p className="login-form-signup-text">
              New to Vape Smoke Universe? <Link to="/signup">Sign up</Link>
            </p>
          </Col>

          <Col xs={12}>
            <ButtonGolden disabled={isLoading || !formState.isValid} />
          </Col>
        </Row>
      </form>
    </CommonAuthScreen>
  )
}
