import { useEffect, useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { InputSearch } from "./InputSearch"
import { Stores } from "../Stores"
import NavbarSettings from "../../components/NavbarSettings/NavbarSettings"
import UserProfile from "../../components/UserProfile/UserProfileNavbar"
import { useStoreContext } from "../../contexts/StoreContext"
import { MyCurrentLocation } from "../../components/MyCurrentLocation/MyCurrentLocation"
import { useMapContext } from "../../contexts/MapContext"
import { useAuthContext } from "../../contexts/AuthContext"
import { useProductsContext } from "../../contexts/ProductsContext"
import { FavouriteStores } from "../FavouriteStores/FavouriteStores"
import { useNavigate } from "react-router-dom"
import { assets } from "../../utils"
import { useCartContext } from "../../contexts/CartContext"
import { MyMap } from "../../components/MyMap"
// import OneSignal from "react-onesignal"
import "./home.scss"

export function Home() {
  const nav = useNavigate()
  const { getLoggedInUser, isUserFlagged } = useAuthContext()
  const {
    isLoading,
    stores,
    getStores,
    favoriteStores,
    getFavouriteStores,
    getUserDeviceId,
    upadateUserDeviceId,
    showMapView,
    setShowMapView,
    markStoreFavourite,
    delFavouriteStore
  } = useStoreContext()
  const { getBrands, getFlavours, getFlavourTypes, getCategories, getInitialLoadItems } =
    useProductsContext()
  const { currentLocation, getCurrentLocation, currentPlaceName } =
    useMapContext()
  const { cartItemTotal, getCart } = useCartContext()

  const [searchText, setSearchText] = useState("")
  const [listedStores, setListedStores] = useState([])

  useEffect(() => {
    getInitialLoadItems();
    getCart()
    window.OneSignal = window.OneSignal || []
  }, [])

  useEffect(() => {
    const OneSignal = window.OneSignal
    if(!localStorage.getItem('isPushNotificationsEnabled')){
      // TODO Notification actions
    }
  },[])

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const onChangeText = debounce(text => {
    setSearchText(text)
  }, 300)

  useEffect(() => {
    const config = {
      params: {
        search: searchText
      }
    }
    getStores(config)
  }, [currentLocation, searchText])

  useEffect(() => {
    getFavouriteStores()
  }, [currentLocation])

  useEffect(() => {
    setListedStores(stores)
  }, [stores])

  const markStoreAsFavorite = (id) => {
    markStoreFavourite({ store: id }).then(() => {
      const config = {
        params: {
          search: searchText
        }
      }
      getStores(config)
      getFavouriteStores()
    }
    )
  }

  const markStoreAsUnFavorite = (id) => {
    delFavouriteStore(id).then(() => {
      const config = {
        params: {
          search: searchText
        }
      }
      getStores(config)
      getFavouriteStores()
    }
    )
  }

  useEffect(() => {
    getLoggedInUser()
    if (!currentLocation.latitude && !currentLocation.longitude) {
      getCurrentLocation()
    }
    // getBrands()
    // getFlavours()
    // getFlavourTypes()
    // getCategories()
    getFavouriteStores()
    // getCart()
    // getUserDeviceId().then(userDeviceId => {
    //   const appId = localStorage.getItem("appId")
    //   const payload = {}
    //   payload.one_device_token = userDeviceId
    //   if (!appId) {
    //     upadateUserDeviceId(payload).then(() =>
    //       localStorage.setItem("appId", userDeviceId)
    //     )
    //   } else if (appId !== userDeviceId) {
    //     upadateUserDeviceId(payload).then(() =>
    //       localStorage.setItem("appId", userDeviceId)
    //     )
    //   }
    // })
  }, [])

  useEffect(() => {
    const favoriteStoreIds = favoriteStores?.map(el => ({ storeId: el.store, favStoreId: el.id }))
    if (stores.length) {
      let newStores = [...stores]
      newStores = newStores.map(el => {
        let obj = { ...el }
        if (favoriteStoreIds.map(el => el.storeId).includes(obj.id)) {
          obj['is_favorite'] = true
          obj['favStoreId'] = favoriteStoreIds.find(el => el.storeId === obj.id)?.favStoreId
        } else {
          obj['is_favorite'] = false
        }
        return obj
      })
      setListedStores(newStores)
    }
  }, [favoriteStores, stores])

  return (
    <div className="home position-relative">
      <div className="w-100 bg-header">
        <Container>
          <Row className="mb-1 pt-4">
            <Col xs="6" lg="9">
              <MyCurrentLocation
                placeName={currentPlaceName}
                setCurrentLocation={getCurrentLocation}
              />
            </Col>
            <Col xs="2" lg="1" className="text-center">
              <NavbarSettings />
            </Col>
            <Col xs="2" lg="1" className="text-center">
              <UserProfile />
            </Col>
            <Col xs="2" lg="1" className="text-center">
              <div
                className="home-cart-icon-wrapper"
                onClick={() => nav("/home/cart")}
              >
                <img src={assets.cartIcon} />
                <div className="cartItemTotal">
                  <span>{cartItemTotal}</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {showMapView ? (
        <Container className="pt-2">
          {favoriteStores?.length > 0 && (
            <FavouriteStores stores={favoriteStores} />
          )}
          <MyMap />
          {stores.length > 0 && (
            <Button
              variant="dark"
              onClick={() => setShowMapView(false)}
              style={{
                position: "sticky",
                bottom: "2rem",
                left: "50%",
                transform: "translateX(-50%)"
              }}
            >
              <span>List view</span>
            </Button>
          )}
        </Container>
      ) : (
        <Container>
          <div className="mb-4">
            <InputSearch onChangeText={onChangeText} />
          </div>
          {isUserFlagged && (
            <Row className="mb-1">
              <Col>
                <div className="flagged-text-wrapper mb-3">
                  <span>You are flagged, please contact support</span>
                </div>
              </Col>
            </Row>
          )}

          {favoriteStores?.length > 0 && (
            <FavouriteStores isUserFlagged={isUserFlagged} stores={favoriteStores} />
          )}

          <Row className="mb-1">
            <Col>
              <h4 className="near-you-text">Near you</h4>
            </Col>
          </Row>
          <Stores stores={listedStores} isUserFlagged={isUserFlagged} markStoreAsFavorite={markStoreAsFavorite}
            markStoreAsUnFavorite={markStoreAsUnFavorite} />
          {stores?.length > 0 && (
            <Button
              variant="dark"
              onClick={() => {
                if (isUserFlagged) {
                  return
                }
                setShowMapView(true)
              }}
              className={`${isUserFlagged ? 'disable-flagged-map' : ''}`}
              style={{
                position: "sticky",
                bottom: "2rem",
                left: "50%",
                transform: "translateX(-50%)"
              }}
            >
              <span>
                Map <img src={assets.mapViewIcon} />
              </span>
            </Button>
          )}
        </Container>
      )}
    </div>
  )
}
