import React, { useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { assets } from "../../utils"
import { useNavigate, useParams } from "react-router-dom"
import { useCartContext } from "../../contexts/CartContext"
import "./PaymentSuccess.scss"

export const PaymentSuccess = () => {
  const nav = useNavigate()
  const params = useParams()

  return (
    <Container className="PaymentSuccess">
      <Row style={{ minHeight: "100vh" }}>
        <Col
          className="text-start pt-5 wrapper"
          style={{ margin: "auto" }}
          xs="11"
          lg="4"
        >
          <Row style={{ marginBottom: "8rem" }} className="text-center">
            <Col xs="12" className="mb-5 text-center">
              <img src={assets.logoDarkIcon} />
            </Col>
            <Col xs="12" className="mb-3 text-center">
              <h2>Thank You!</h2>
            </Col>
            <Col xs="12" className="mb-3 text-center order-success-details">
              <p className="text-style mb-2">Your order is processing!</p>
              <p className="text-style mb-2">Your order number is <strong>{`#${params.id}`}</strong></p>
              <p className="text-style mb-2 text-important">Please make sure your government issued ID matches the name on the order.</p>
              <p className="text-style">You will receive a text when your order is ready to pick up.</p>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Button
                variant="dark"
                style={{ width: "100%" }}
                onClick={() => nav("/home/my-orders")}
              >
                Continue
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
