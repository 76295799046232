import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import {
  ItemAddedToCartAlert,
  ProductStockAlert
} from "../../components/AlertDismissible"
import { Loader } from "../../components/Loader"
import { useAuthContext } from "../../contexts/AuthContext"
import { useCartContext } from "../../contexts/CartContext"
import { useProductsContext } from "../../contexts/ProductsContext"
import Description from "./Description"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { ImageSlider } from "./ImageSlider"
import { NotifyPopup } from "./NotifyPopup"
import "./ProductDetails.scss"

export const ProductDetails = () => {
  const [searchParams] = useSearchParams()
  const { state } = useLocation()
  const { loggedUserId, getLoggedInUser } = useAuthContext()
  const {
    isLoading,
    product,
    getProduct,
    getRelatedProducts,
    notifyProductStock,
    category,
    getCategory,
    productVariations,
    getProductVariation,
    setProductVariations,
    setCategory,
    getInventory,
    getDealForAProuct,
    clearProductContext,
    getInventoryIdForCompProduct
  } = useProductsContext()
  const {
    isLoading: cartLoading,
    showAlert,
    setShowAlert,
    showItemAddedAlert,
    setShowItemAddedAlert,
    getCart,
    addItemToCart
  } = useCartContext()
  const {
    productsku: defaultProductsku,
    other_variants,
  } = product
  const nav = useNavigate()
  const [otherVariants, setOtherVariants] = useState([])
  const [variantSelected, setVariantSelected] = useState({})
  const [productskuSelected, setProductskuSelected] = useState("")
  const [quantity, setQuantity] = useState("")
  const [show, setShow] = useState(false)
  const [groupedVariants, setGroupedVariants] = useState([])
  const [variationObj, setVariationObj] = useState({})
  const [selectedKey, setSelectedKey] = useState("")
  const [ID, setID] = useState("")
  const [productAttributes, setProductAttributes] = useState([])
  const [quantitySelected, setQuantitySelected] = useState(1)
  const [pvID, setPvID] = useState("")
  const [productInventoryId, setProductInventoryId] = useState("")
  const [relatedProduts, setRelatedProduts] = useState([])

  const onClickFlavourChange = id => {
    const newVariants = groupedVariants.map(item => ({
      ...item,
      checked: false
    }))
    const index = newVariants.findIndex(item => item.id == id)
    newVariants[index].checked = true
    newVariants[index].value = newVariants[index].value?.map(item => {
      if (item.id == id) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
    const { productsku, quantity } = newVariants[index]?.value?.[0]
    setVariantSelected(productsku)
    setProductskuSelected(id)
    setQuantity(quantity)
    setGroupedVariants(newVariants)
  }

  const onClickNicotineChange = item => {
    const { id, quantity } = item
    setProductskuSelected(id)
    setQuantity(quantity)
  }

  const setShowNotify = () => {
    setShow(true)
  }

  const onClickNotify = () => {
    const { id } = variantSelected
    notifyProductStock(productInventoryId).then(() => {
      setShow(false)
    })
  }

  const getImages = () => {
    let images = []
    Object.keys(variationObj).map(key => {
      if (variationObj[key].selected) {
        images = variationObj[key].images
      }
    })
    return images
  }

  useEffect(() => {
    if (searchParams.get("id")) {
      getProduct(searchParams.get("id"))
    }
  }, [searchParams.get("id")])

  useEffect(() => {
    getLoggedInUser()
    getCart()

    return () => {
      setShowAlert(false)
      setShowItemAddedAlert(false)
      setVariationObj({})
      setProductVariations([])
      setCategory({})
    }
  }, [])

  useEffect(() => {
    if (product?.id) {
      const { id, category } = product.product
      Promise.allSettled([
        getProductVariation({
          params: {
            product: id
          }
        }),
        getCategory(category.id)
      ])
      getRelatedProducts({ params: { limit: 10, offset: 0, category: category.id } }).then(resp => {
        let relatedProducts = resp.results?.filter(product => product.id != searchParams.get("id"))
        setRelatedProduts(relatedProducts)
      })
    }
  }, [product])

  useEffect(() => {
    const productAttributes = []
    const variationObj = {}
    category?.variations?.sort((a, b) => a.ordering - b.ordering)

    if (category?.attribute && product?.product?.attributes) {
      category.attribute.forEach(({ id, name }) => {
        product.product.attributes.forEach(({ attribute, value }) => {
          if (attribute.id === id) {
            productAttributes.push({ id, name, value })
          }
        })
      })
    }

    if (category?.variations?.length > 0) {
      productVariations.forEach(({ id, images, productvariations }) => {
        productvariations.map(({ variationvalue }) => {
          if (
            variationvalue.variation.id === category?.variations[0].variation.id
          ) {
            const _filterData = productvariations
              .filter(
                ({ variationvalue }) =>
                  variationvalue.variation.id !=
                  category?.variations[0].variation.id
              )
              .map(item => ({ ...item, cid: id }))

            if (!variationObj[variationvalue.id]) {
              variationObj[variationvalue.id] = {
                pvID: id,
                id: variationvalue.id,
                value: variationvalue.value,
                images: images,
                values: [..._filterData],
                selected: id === product.variation
              }
            } else {
              variationObj[variationvalue.id].values.push(..._filterData)
            }
          }
        })
      })
    }

    Object.keys(variationObj).forEach(key => {
      const { values } = variationObj[key]
      values.forEach(value => {
        if (value?.cid) {
          if (value.cid === product.variation) {
            variationObj[key].selected = true
          }
        }
      })
    })
    setProductAttributes(productAttributes)
    setVariationObj(variationObj)
    setID(product.variation)
  }, [category, productVariations])

  const onClickAddToCart = () => {
    if (productInventoryId) {
      const payload = {
        productstock: productInventoryId,
        quantity: quantitySelected
      }
      addItemToCart(payload)
    }
  }

  useEffect(() => {
    if (ID && pvID) {
      getInventory(product.store, ID).then(response => {
        if (response) {
          const pid = response.id
          setProductInventoryId(pid)
          setQuantity(response?.quantity)
        }
      })
    } else if (!ID && pvID) {
      getInventory(product.store, pvID).then(response => {
        if (response) {
          const pid = response?.id
          setProductInventoryId(pid)
          setQuantity(response?.quantity)
        }
      })
    }
  }, [pvID, ID])

  useEffect(() => {
    return () => {
      clearProductContext()
    }
  }, [])

  const navigateCompatible = (id) => {
    const config = {
      params: {
        variation__product: id
      }
    }
    getInventoryIdForCompProduct(config).then(resp => {
      const inventoryId = resp.results[0].id;
      if(inventoryId){
      const url = {}
      url.pathname = "/home/product-details/"
      url.search = `?id=${inventoryId}`
      nav(url, {
        state: {
          storeId: state?.storeId
        }
      })
    } else {
      alert('Product is not available in this store')
    }
    })
  }

  return (
    <Container className="ProductDetails min-vh-100" fluid>
      <Header
        id={productskuSelected}
        loggedUserId={loggedUserId}
        storeId={state?.storeId}
      />
      <Row>
        <Col>
          {showAlert && (
            <ProductStockAlert
              show={showAlert}
              setShow={setShowAlert}
              message={`Sorry, we only have ${quantity} in stock`}
            />
          )}

          {showItemAddedAlert && (
            <ItemAddedToCartAlert
              show={showItemAddedAlert}
              setShow={setShowItemAddedAlert}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col xs="12" lg="6">
          <ImageSlider images={getImages()} />
        </Col>
        <Col xs="12" lg="6">
          <Description
            product={product?.product}
            flavour={variantSelected?.flavour}
            otherVariants={otherVariants}
            loading={isLoading}
            onClickFlavour={onClickFlavourChange}
            groupedVariants={groupedVariants}
            onClickNicotineChange={onClickNicotineChange}
            relatedProducts={relatedProduts || []}
            variations={category?.variations?.filter(el => el.variation.name !== 'Default') || []}
            variationObj={variationObj}
            selectedKey={selectedKey}
            setSelectedKey={id => {
              const update = { ...variationObj }
              Object.keys(update).forEach(key => (update[key].selected = false))
              update[id].selected = true
              setVariationObj(update)
              setSelectedKey(id)
            }}
            productVariationID={ID}
            setProductVariationID={setID}
            productAttributes={productAttributes}
            setPvID={setPvID}
            getDealForAProuct={getDealForAProuct}
            navigateCompatible={navigateCompatible}
          />
        </Col>
        <Col xs="12" lg="6" className="ms-auto">
          <Footer
            setShowNotify={setShowNotify}
            productstock={Number(productskuSelected)}
            quantity={quantity}
            loading={isLoading || cartLoading}
            onClickAddToCart={onClickAddToCart}
            quantitySelected={quantitySelected}
            setQuantitySelected={setQuantitySelected}
          />
        </Col>
      </Row>

      <Loader loading={isLoading || cartLoading} />
      <NotifyPopup
        show={show}
        setShowNotify={setShowNotify}
        onHide={() => setShow(false)}
        onClickNotify={onClickNotify}
        loading={isLoading}
      />
    </Container>
  )
}
