import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CommonAuthScreen } from "../CommonAuthScreen";
import { ButtonGolden } from "../../components/ButtonGolden";
import "./checkEmail.scss";
import { ALL_ROUTES } from "../../app-config";

export function CheckEmail() {
  const nav = useNavigate();
  const { handleSubmit } = useForm();

  const onSubmit = async (vals) => {
    nav(ALL_ROUTES.login);
  };

  return (
    <CommonAuthScreen
      showLogo={false}
      linkTo={ALL_ROUTES.resetPassword}
      title={<p style={{ marginTop: "2.5rem" }}>Check your email </p>}
      subTitle={
        <>
          We have sent you a password recovery <br /> instructions to your email
        </>
      }
    >
      <form className={"signup"} onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col xs={12} className="mb-3">
            <p
              className="text-center"
              style={{ marginTop: "2rem", marginBottom: "2.5rem" }}
            >
              Didn’t receive the email <Link>Try again</Link>
            </p>
          </Col>

          <Col xs={12}>
            <ButtonGolden label="Log in" />
          </Col>
        </Row>
      </form>
    </CommonAuthScreen>
  );
}
