import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { Header } from "./Header"
import InputField from "../../components/InputField"
import { assets } from "../../utils"
import { Button, Container } from "react-bootstrap";
import { useCartContext } from "../../contexts/CartContext"
import { number as cardNumberValidator, expirationDate as expiryDateValidator, cvv as cvvValidator } from 'card-validator';

export function Payment() {

  const {
    pickupOptions,
    checkoutOrder,
    cartPriceDetails,
    groupedStoreAndCart,
    setCurrentProductsPreview
  } = useCartContext()
  const nav = useNavigate()
  const { control, register, trigger, formState, handleSubmit, getValues } = useForm()
  const [cardImage, setCardImage] = useState('')
  const [storeAndProductDetails, setStoreAndProductDetails] = useState([])
  const [isPaymentInitiated, setIsPaymentInitiated] = useState(false)
  const paymentInitaited = useRef(false)

  const onClickPay = async () => {
    if (!paymentInitaited.current) {
      paymentInitaited.current = true
      const formData = getValues()
      if (!formState.isValid) {
        paymentInitaited.current = false
        return
      }
      const cardData = {
        pickup_type: pickupOptions.find(el => el.checked).value,
        name: formData['name'],
        number: formData['card-number'],
        exp_month: formData['expiry-date'].slice(0, 2),
        exp_year: `20${formData['expiry-date'].slice(-2)}`,
        cvv: formData['cvv']
      }
      checkoutOrder(cardData).then(resp => {
        if (resp.status_code === 200 && resp.success) {
          paymentInitaited.current = false
          nav(`/home/payment-success/${resp.payload.user}/${resp.payload?.order_items?.[0]?.order}/${resp.payload.order_id}`)
        }
      }).catch(error => {
        paymentInitaited.current = false
        setIsPaymentInitiated(false)
      })
    }
  }

  useEffect(() => {
    const arr = [];
    if(Object.keys(groupedStoreAndCart).length===0){
      // navigate to cart screen
      nav(-1)
      return
    }
    Object.keys(groupedStoreAndCart).forEach((item) => {
      const obj = {
        storeName: item,
        productData: groupedStoreAndCart[item],
        numberOfProducts: groupedStoreAndCart[item]?.length
      }
      arr.push(obj)
    })
    setStoreAndProductDetails(arr)
  }, [])

  return (
    <Container>
      <Header />
      <Row>
        <Col className="payment-items-wrapper">
          <h5>Your Card Details</h5>
          <form className="payment-form">
            <Row>
              <Col xs={12} lg={8}>
                <InputField
                  label="Card Holder Name"
                  name="name"
                  placeholder="John Due"
                  rules={{
                    required: "Card holder name required!",
                  }}
                  control={control}
                  trigger={trigger}
                  className="input-form-text"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={8} className={`${cardImage}-card`}>
                <InputField
                  label="Card Number"
                  name="card-number"
                  placeholder="1234-5678-1234-5678"
                  type='number'
                  rules={{
                    required: "Card number required!",
                    validate: (val) => {
                      console.log('card-number', cardNumberValidator(val))
                      if (!cardNumberValidator(val).isPotentiallyValid) {
                        return 'Card Invalid'
                      } else {
                        setCardImage(cardNumberValidator(val).card.type)
                      }
                    },
                    minLength: {
                      value: 16,
                      message: "Minimum 16 digits required"
                    }
                  }}
                  control={control}
                  trigger={trigger}
                  className="input-form-text"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} xs={6}>
                <InputField
                  label="Expiration Date"
                  name="expiry-date"
                  placeholder="MM/YY"
                  rules={{
                    required: "Expiration date required!",
                    validate: (val) => {
                      if (!expiryDateValidator(val).isPotentiallyValid) {
                        return 'Invalid Expiry date'
                      }
                    }
                  }}
                  control={control}
                  trigger={trigger}
                  className="input-form-text"
                />
              </Col>

              <Col lg={4} xs={6}>
                <InputField
                  label="CVV"
                  name="cvv"
                  placeholder="123"
                  rules={{
                    required: "CVV required!",
                    validate: (val) => {
                      if (!cvvValidator(val, 3).isPotentiallyValid) {
                        return 'Invalid CVV'
                      }
                    }
                  }}
                  control={control}
                  trigger={trigger}
                  className="input-form-text"
                />
              </Col>
            </Row>
          </form>
        </Col>

        <Col>
          <h5>Order Summary</h5>
          <Row className="payment-subtotal-details">
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Subtotal:</p>
              <p>{`$ ${cartPriceDetails.subTotal}`}</p>
            </Col>
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Tax:</p>
              <p>{`$ ${cartPriceDetails.salesTax}`}</p>
            </Col>
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Cecet Tax (0.0%):</p>
              <p>{`$ ${cartPriceDetails.nicotineTax}`}</p>
            </Col>
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Discount:</p>
              <p>{`$ ${cartPriceDetails.discount}`}</p>
            </Col>
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Promo Code:</p>
              <p>$ 0.0</p>
            </Col>
            <Col
              xs="12"
              lg="8"
              className="total-price-item d-flex align-items-center justify-content-between"
            >
              <p>{`Total (2 items):`}</p>
              <p>{`$ ${cartPriceDetails.priceToPay}`}</p>
            </Col>
            <hr />
            {storeAndProductDetails.map((item) => <div key={item.storeName}>
              <Col xs="12" lg="8">
                <div className="d-flex align-items-center mb-2 ">
                  <img src={assets.mapIcon} />
                  <h5>{item.storeName}</h5>
                </div>
              </Col>
              <Row className='pt-4 p-2 payment-header'>
                <Col xs="10" md="11" lg="11" xl="11">
                  <div className="d-flex align-items-center mb-2 ">
                    <img src={assets.productIcon} />
                    <h5>Products : {item.numberOfProducts}</h5>
                  </div>
                </Col>
                <Col xs="1" md="1" lg="1" xl="1">
                  <img
                    src={assets.forwardArrow}
                    className="arrow-forward-icon"
                    onClick={() => {setCurrentProductsPreview(item.productData); nav("/home/my-products")}}
                  />
                </Col>
              </Row>
            </div>)}
            <Col xs="12" md="12">
              <Button type="button" className="btn-paynow" disabled={!formState.isValid && isPaymentInitiated}
                onClick={() => onClickPay()}>
                Pay
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
