import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"
import { GOOGLE_API_KEY, ONE_SIGNAL_APP_ID } from "../utils"
import { useMapContext } from "./MapContext"
import OneSignal from "react-onesignal"

const StoreContext = createContext()

const StoreProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [stores, setStores] = useState([])
  const [store, setStore] = useState({})
  const { currentLocation, calculateDistance } = useMapContext()
  const [favoriteStores, setFavoriteStores] = useState([])
  const [showMapView, setShowMapView] = useState(false)

  const getStores = config => {
    setIsLoading(true)
    API.getStores(config).then(async response => {
      const { latitude, longitude } = currentLocation

      if (!longitude && !latitude) {
        return setStores(response.data.payload.results)
      }
      const stores = await Promise.all(
        response.data.payload.results.map(async store => {
          const { lat, lng } = store
          const distance = await calculateDistance({ lat, lng })
          return { ...store, distance }
        })
      )
      stores.sort((a, b) =>
        a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0
      )
      setStores(stores)
    })
  }

  const getStore = id => {
    setIsLoading(true)
    API.getStore(id).then(async response => {
      const store = { ...response.data.payload }
      const { lat, lng } = store
      const { latitude, longitude } = currentLocation
      if ((!lat && !lng) || (!longitude && !latitude)) {
        return setStore(store)
      }
      const distance = await calculateDistance({ lat, lng })
      store.distance = distance
      setStore(store)
    })
  }

  const getStoreReviewDetails = ({ latitude, longitude, storeName }) => {
    const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${longitude},${latitude}&radius=100&type=store&keyword=${storeName}&key=${GOOGLE_API_KEY}`
    setIsLoading(true)
    API.getStoreReview(url)
      .then(response => console.log(response.data))
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getFavouriteStores = id => {
    setIsLoading(true)
    API.getFavouriteStore(id)
      .then(async response => {
        const { results } = response.data.payload
        const { latitude, longitude } = currentLocation

        if (!longitude && !latitude) {
          return setFavoriteStores(results)
        }
        const stores = await Promise.all(
          results.map(async store => {
            const { lat, lng } = store
            const distance = await calculateDistance({ lat, lng })
            return { ...store, distance:distance }
          })
        )
        stores.sort((a, b) =>
          a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0
        )
        setFavoriteStores(stores)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const markStoreFavourite = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.markStoreFavourite(payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const delFavouriteStore = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.delFavouriteStore(id)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getUserDeviceId = () => {
    return new Promise((resolve, reject) => {
      OneSignal.init({ appId: ONE_SIGNAL_APP_ID })
        .then(() => {
          OneSignal.getUserId().then(userId => {
            resolve(userId)
          })
        })
        .catch(error => console.log("getUserDeviceId error", error))
    })
  }

  const upadateUserDeviceId = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.updateDeviceId(payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  return (
    <StoreContext.Provider
      value={{
        isLoading,
        stores,
        getStores,
        store,
        getStore,
        getStoreReviewDetails,
        favoriteStores,
        getFavouriteStores,
        markStoreFavourite,
        delFavouriteStore,
        getUserDeviceId,
        upadateUserDeviceId,
        showMapView,
        setShowMapView
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export const useStoreContext = () => useContext(StoreContext)

export default StoreProvider
