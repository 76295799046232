import React from "react"
import { Button } from "react-bootstrap"
import "./styles.scss"

export const ButtonGolden = ({
  label = "Log in",
  type = "submit",
  disabled,
  onClick
}) => {
  return (
    <Button
      variant="primary"
      type={type}
      className="button-golden pwa"
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}
