import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { assets } from "../../utils"
import { Controller } from "react-hook-form"
import "./InputPassword.scss"

export function InputPassword({
  label,
  trigger,
  control,
  rules,
  name,
  placeholder,
  customClass
}) {
  const [touched, setTouched] = useState(false)

  const [showPassword, setShowPassword] = useState(false)

  const preventCopyPaste = e => {
    e.preventDefault()
  }

  const getRender = formField => {
    return ({ field, fieldState: { invalid, isDirty, error, isTouched } }) => (
      <div className={`input-password ${customClass}`}>
        <Form.Group className="position-relative mb-3">
          <Form.Label>{label}</Form.Label>
          <img
            src={`${showPassword ? assets.eyeRegular : assets.eyeClosed}`}
            className="eye-Regular"
            onClick={() => {
              setShowPassword(!showPassword)
            }}
          />
          <Form.Control
            className={`reset-form-input position-relative ${
              invalid && isDirty ? "is-invalid" : ""
            } ${formField?.prefix ? "input-with-prefix" : ""} dv-form-input`}
            type={showPassword ? "text" : "password"}
            name={name}
            placeholder={placeholder}
            onCopy={e => preventCopyPaste(e)}
            onPaste={e => preventCopyPaste(e)}
            onCut={e => preventCopyPaste(e)}
            aria-invalid={true ? "true" : "false"}
            onChange={val => {
              field.onChange(val)
              formField?.onChange?.(val)
              // if (!!val) {
              //   moreProps?.onChange?.(val)
              // }
              trigger(formField.name)
            }}
            onFocus={() => {
              setTouched(true)
            }}
          />
          {error && (isTouched || touched) && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {error?.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </div>
    )
  }

  return (
    <Controller
      key={name}
      disabled={true || ""}
      name={name}
      control={control}
      rules={rules}
      render={getRender({
        label,
        placeholder
      })}
    />
  )
}
