import { useState, useEffect } from "react"
import { assets, formatDateVouched, formatPhoneNumber } from "../../utils"
import { useMapContext } from "../../contexts/MapContext"
import "./ItemStore.scss"

export const ItemStore = ({ store, status, updated_on, order_value }) => {
  const { calculateDistance, getCurrentLocation, currentLocation } = useMapContext()
  const [distance, setDistance] = useState(0)

  useEffect(() => {
    if (store?.lat && store?.lng) {
      calculateDistance({ lat: store.lat, lng: store.lng }).then(distance =>
        setDistance(Number(distance).toFixed(1))
      )
    }
  }, [currentLocation])

  useEffect(()=>{
    if(!Object.keys(currentLocation).length){
      getCurrentLocation()
    }
  })

  return (
    <>
      {status === "Completed" ? (
        <>
          <div className="d-flex align-items-center mb-5 position-relative">
            <img src={assets.mapIcon} />
            <div className="store-details ms-3">
              <h5 className="store-name">{store?.store_name ? store?.store_name : store?.name}</h5>
              <p>{formatDateVouched(updated_on)}</p>
              <p>{`$ ${Number(order_value).toFixed(2)}`}</p>
            </div>
            <div className="ms-auto">
              <img src={assets.forwardArrow} />
            </div>
          </div>
        </>
      ) : (
        <>
          <h5 className="mb-3">Store</h5>
          <div className="d-flex align-items-center mb-5">
            <img src={assets.mapIcon} />
            <div className="store-details ms-3">
              <h5 className="store-name">{store?.store_name ? store?.store_name : store?.name}</h5>
              <p>{`${distance} mi from your location`}</p>
              <p>{formatPhoneNumber(store?.phone_number)}</p>
              <p>{store?.address?.replace(', United States','')}</p>
            </div>
          </div>
        </>
      )}
    </>
  )
}
