import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { ButtonGolden } from "../../components/ButtonGolden"
import InputField from "../../components/InputField"
import { useAuthContext } from "../../contexts/AuthContext"
import { assets, validation } from "../../utils"
import { Loader } from "../../components/Loader"
import { ALL_ROUTES } from "../../app-config"

import "./UserProfile.scss"

export const UserProfile = ({ onUpdateProfile, show }) => {
  const nav = useNavigate()
  const {
    isLoading,
    userLogout,
    loggedUserId,
    getLoggedInUser,
    getUserProfile,
    deactivateAccount
  } = useAuthContext()
  const { control, setValue, reset, trigger, handleSubmit } = useForm()
  const [selectedDob, setSelectedDob] = useState(null)

  const dobYearsBefore = 100
  let dobSelectedDate
  if (!!selectedDob) {
    dobSelectedDate = new Date(selectedDob)
  } else {
    dobSelectedDate = new Date()
    dobSelectedDate.setFullYear(new Date().getFullYear() - 20)
  }
  const [genderOptions, setGenderOptions] = useState([
    {
      label: "Male",
      value: "M",
      selected: true
    },
    {
      label: "Female",
      value: "F",
      selected: false
    }
  ])
  const [photo, setPhoto] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)

  const userFields = [
    "address",
    "city",
    "country",
    "date_of_birth",
    "email",
    // "gender",
    "name",
    // "phone_number",
    // "photo",
    "state",
    "zipcode"
  ]
  const onSubmit = async vals => {
    console.log(vals)
    const payload = { ...vals }
    if (photo) {
      payload.photo = photo
    }
    onUpdateProfile(loggedUserId, payload)
    // reset()
    // userLogin(vals).then(() => nav("/home/stores"))
  }

  const previewFile = e => {
    const reader = new FileReader()
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setPhoto(selectedFile)
      reader.readAsDataURL(selectedFile)
    }
    reader.onload = readerEvent => {
      if (selectedFile.type.includes("image")) {
        setImagePreview(readerEvent.target.result)
      }
    }
  }

  useEffect(() => {
    if (loggedUserId && show) {
      console.log('[UserProfile useEffect] getUserProfile')
      getUserProfile(loggedUserId).then(user => {
        userFields.forEach(key => {
          if (user[key]) {
            setValue(key, user[key])
          }
        })
        const { phone_number, gender, photo } = user
        const phoneNumber = !!phone_number ? phone_number.substr(phone_number.length - 10) : "" // last 10 digit
        setValue("phone_number", phoneNumber)
        setImagePreview(photo)
      })
    }
  }, [loggedUserId, show])

  useEffect(() => {
    getLoggedInUser()
  }, [])

  return (
    <Container className="user-profile-container">
      <form className="user-profile" onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col xs={12} className="mb-3">
            {imagePreview ? (
              <div className="preview-profilePhoto">
                <label htmlFor="profilePhoto" className="btn">
                  {imagePreview != null && <img src={imagePreview} alt="" />}
                </label>
                <input
                  id="profilePhoto"
                  className="d-none"
                  type="file"
                  onChange={previewFile}
                />
              </div>
            ) : (
              <>
                <label htmlFor="profilePhoto" className="btn">
                  <img src={assets.fileUploadIcon} />
                </label>
                <input
                  id="profilePhoto"
                  className="d-none"
                  type="file"
                  onChange={previewFile}
                />
              </>
            )}
          </Col>
          <Col xs={12} className="mb-3">
            <InputField
              label="Full Name"
              name="name"
              placeholder=""
              rules={{
                required: "Full Name required!",
                validate: { ...validation.name }
              }}
              compType=""
              control={control}
              trigger={trigger}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <InputField
              label="Email"
              name="email"
              placeholder=""
              rules={{
                required: "Email required!",
                validate: { ...validation.email }
              }}
              compType=""
              control={control}
              trigger={trigger}
              readOnly={true}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <InputField
              label="Phone"
              name="phone_number"
              placeholder=""
              rules={{
                required: "Phone required!",
                validate: { ...validation.phone }
              }}
              compType=""
              control={control}
              trigger={trigger}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <InputField
              label="Date of birth"
              name="date_of_birth"
              type="date"
              rules={{
                required: "Date of birth required!",
                validate: { ...validation.dob }
              }}
              control={control}
              trigger={trigger}
              dobYearsBefore={dobYearsBefore}
              datepickerSelectedDate={dobSelectedDate}
              maxDate={new Date()}
            />
          </Col>
          <Col xs={12} className="mb-4">
            <InputField
              label="Address"
              name="address"
              placeholder=""
              // rules={{
              //   required: "Address required!",
              //   validate: { ...validation.street }
              // }}
              compType=""
              control={control}
              trigger={trigger}
              className="search-box"
            />
          </Col>
          <Col xs={12} className="mb-4">
            <InputField
              label="City"
              name="city"
              placeholder=""
              // rules={{
              //   required: "City required!",
              //   validate: { ...validation.city }
              // }}
              compType=""
              control={control}
              trigger={trigger}
              className="search-box"
            />
          </Col>
          <Col xs={12} className="mb-4">
            <InputField
              label="Zipcode"
              name="zipcode"
              placeholder=""
              // rules={{
              //   required: "Zipcode required!",
              //   validate: { ...validation.zip }
              // }}
              compType=""
              control={control}
              trigger={trigger}
              className="search-box"
            />
          </Col>
          <Col xs={12} className="mb-4">
            <InputField
              label="State"
              name="state"
              placeholder=""
              // rules={{
              //   required: "State required!",
              //   validate: { ...validation.state }
              // }}
              compType=""
              control={control}
              trigger={trigger}
              className="search-box"
            />
          </Col>
          <Col xs={12} className="mb-4">
            <InputField
              label="Country"
              name="country"
              placeholder=""
              // rules={{
              //   required: "Country required!",
              //   validate: { ...validation.street }
              // }}
              compType=""
              control={control}
              trigger={trigger}
              className="search-box"
            />
          </Col>
          <Col xs={12} className="mb-4">
            <ButtonGolden label="Update" />
          </Col>
          <Col xs={12} className="mb-2">
            <div>
              <img src={assets.profileRedIcon} />
              <Button
                variant="light"
                className="sign-out-text"
                onClick={e => {
                  e.preventDefault()
                  userLogout().then(() => nav(ALL_ROUTES.login))
                }}
              >
                Sign out
              </Button>
            </div>
          </Col>
          <Col xs={12} className="mb-4">
            <div>
              <img src={assets.profileRedIcon} />
              <Button
                variant="light"
                className="sign-out-text"
                disabled={isLoading}
                onClick={e => {
                  e.preventDefault()
                  // deactivateAccount(loggedUserId).then(() => nav(ALL_ROUTES.login))
                  // userLogout().then(() => nav(ALL_ROUTES.login))
                }}
              >
                Deactivate account
              </Button>
            </div>
          </Col>
        </Row>
      </form>
      <Loader loading={isLoading} />
    </Container>
  )
}
