import React from "react"
import { Container } from "react-bootstrap"
import { assets } from "../../utils"
import "./PaymentProcessing.scss"

export const PaymentProcessing = ({ formAction, paymentToken }) => {
  return (
    <Container className="PaymentProcessing">
      <div className="row">
        <div className="col">
          <img src={assets.logoDarkIcon} />
        </div>
        <form
          method="post"
          action={formAction}
          id="formAuthorizeNetTestPage"
          name="formAuthorizeNetTestPage"
        >
          <input
            id="inputToken"
            type="hidden"
            name="token"
            value={paymentToken}
          />
          <div className="col continuing-payment-text">
            <p>Continuing to Payment Page...</p>
          </div>
          <div className="col payment-processing-text">
            <h2>Please Do Not Close the page while payment is processing...</h2>
          </div>
          <h1></h1>
          <button id="btnContinue" style={{ display: "none" }}></button>
        </form>
      </div>
    </Container>
  )
}
