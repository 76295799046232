import React from "react"
import { Navigate, useLocation, useNavigate, useRoutes } from "react-router-dom"
import { CheckEmail } from "./pages/CheckEmail"
import { ForgotPasswordVerify } from "./pages/ForgotPasswordVerify"
import { Home } from "./pages/Home/Home"
import { Login } from "./pages/Login"
import { Payment } from "./pages/Payment"
import { Products } from "./pages/Products"
import { ResetPassword } from "./pages/ResetPassword"
import { SetNewPassword } from "./pages/SetNewPassword"
import { Signup } from "./pages/Signup"
import { TokenVerification } from "./pages/TokenVerification"
import { VerifyEmail } from "./pages/VerifyEmail"
import { MyMap } from "./components/MyMap"
import { SearchableMap } from "./components/SearchableMap"
import { ProductDetails } from "./pages/ProductDetails/ProductDetails"
import { Cart } from "./pages/Cart/Cart"
import { MyOrders } from "./pages/MyOrders"
import { Feedback } from "./pages/Feedback"
import { MyOrderDetails } from "./pages/MyOrderDetails/MyOrderDetails"
import { VouchedVerification } from "./pages/VouchedVerification"
import { Onboarding } from "./pages/VerifyUser"
import { useEffect } from "react"
import { VerifyUser } from "./pages/VerifyUser/VerifyUser"
import { PaymentSuccess } from "./pages/PaymentSuccess"
import { useAuthContext } from "./contexts/AuthContext"
import OneSignalPush from "./utils/oneSignalPush"
import MyProducts from "./pages/MyProducts/myProducts"
import { ALL_ROUTES, APPCONFIG } from "./app-config"

const AppRoutes = () => {
  const { getUserProfile } = useAuthContext()
  const nav = useNavigate()
  const location = useLocation()

  useEffect(() => {
    console.log('[AppRoutes useEffect]')
    const token = localStorage.getItem("token")
    const user = JSON.parse(localStorage.getItem("user"))

    // on welcome page, ensure to clear user session data
    if(token && user && location.pathname === ALL_ROUTES.welcome) {
      console.log('[AppRoutes useEffect] on welcome page, ensure to clear user session data')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      window.location.reload()
      return () => {}
    }

    // check user status and redirect accordingly
    if (token && user && user?.userprofile) {
      console.log('[AppRoutes useEffect] check user status and redirect accordingly')
      const { email_verified, id_verified } = user?.userprofile
      if (
        token &&
        email_verified &&
        id_verified &&
        location.pathname.includes(APPCONFIG.dashboardPrefix)
      ) {
        nav({
          pathname: location.pathname,
          search: location.search,
          state: location.state
        })
      } else if (token && email_verified && id_verified) {
        nav(ALL_ROUTES.HOME.stores)
      } else if (token && email_verified && !id_verified) {
        nav(ALL_ROUTES.onboarding)
      } else if (token && !email_verified) {
        nav(ALL_ROUTES.verifyEmail)
      } else if (location.pathname.includes(APPCONFIG.dashboardPrefix)) {
        nav(ALL_ROUTES.login)
      }
    } else {
      nav(ALL_ROUTES.login)
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem("token")) {
      console.log('[AppRoutes useEffect] getUserProfile')
      getUserProfile("").then(resp => {
        if (!resp.one_device_token) {
          OneSignalPush()
        }
      })
    }
  }, [localStorage.getItem("token")])
  const element = useRoutes([
    { path: ALL_ROUTES.login, element: <Login />, index: true },
    { path: ALL_ROUTES.signup, element: <Signup /> },
    { path: ALL_ROUTES.resetPassword, element: <ResetPassword /> },
    { path: ALL_ROUTES.verifyEmail, element: <VerifyEmail /> },
    { path: ALL_ROUTES.checkEmail, element: <CheckEmail /> },
    { path: ALL_ROUTES.otpVerification, element: <TokenVerification /> },
    { path: ALL_ROUTES.forgotPasswordVerify, element: <ForgotPasswordVerify /> },
    { path: ALL_ROUTES.setNewPassword, element: <SetNewPassword /> },
    { path: ALL_ROUTES.onboarding, element: <Onboarding /> },
    { path: ALL_ROUTES.verifyUser, element: <VerifyUser /> },
    { path: ALL_ROUTES.idVerification, element: <VouchedVerification /> },

    { path: ALL_ROUTES.HOME.stores, element: <Home /> },
    { path: ALL_ROUTES.HOME.storeDetails, element: <Products /> },
    { path: ALL_ROUTES.HOME.searchAddress, element: <SearchableMap /> },
    { path: ALL_ROUTES.HOME.myMap, element: <MyMap /> },
    { path: ALL_ROUTES.HOME.productDetails, element: <ProductDetails /> },
    { path: ALL_ROUTES.HOME.cart, element: <Cart /> },
    { path: ALL_ROUTES.HOME.myOrders, element: <MyOrders /> },
    { path: ALL_ROUTES.HOME.feedback, element: <Feedback /> },
    { path: ALL_ROUTES.HOME.myProducts, element: <MyProducts /> },
    { path: ALL_ROUTES.HOME.myOrderDetails, element: <MyOrderDetails /> },
    { path: ALL_ROUTES.HOME.paymentSuccess, element: <PaymentSuccess /> },
    { path: ALL_ROUTES.HOME.payment, element: <Payment /> }
  ])

  return element
}

export default AppRoutes
