import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useCartContext } from "../../contexts/CartContext"
import { assets } from "../../utils"
import "./ProductDetails.scss"

export const Footer = ({
  loading,
  setShowNotify,
  productstock,
  quantity,
  onClickAddToCart,
  quantitySelected,
  setQuantitySelected
}) => {
  const { addItemToCart } = useCartContext()
  const [isOutOfStock, setIsOutOfStock] = useState(false)
  // const [quantitySelected, setQuantitySelected] = useState(1)
  useEffect(() => {
    setIsOutOfStock(quantity === 0)
  }, [quantity])

  // const onClickAddToCart = () => {
  //   addItemToCart({
  //     productstock,
  //     quantity: quantitySelected,
  //     stockQuantity: quantity
  //   })
  // }

  return (
    <Row className="Footer">
      {isOutOfStock && (
        <Col xs="12" className="foolter-col">
          <p className="out-of-stock-text">Out of stock</p>
          <div className="add-to-cart-btn">
            <Button variant="secondary" onClick={setShowNotify}>
              Notify when restocked
            </Button>
          </div>
        </Col>
      )}
      {false && (
        <Col xs="12" className="foolter-col">
          <div className="add-to-cart-btn">
            <Button variant="secondary" onClick={() => alert("ALERT")}>
              You are flagged, please contact support
            </Button>
          </div>
        </Col>
      )}
      {!isOutOfStock && (
        <>
          <Col xs="6" md="4" className="foolter-col">
            <div className="inc-dec-btn mt-4 align-items-center justify-content-around">
              <Button
                variant="secondary"
                disabled={quantitySelected === 1 ? true : false}
                onClick={() =>
                  setQuantitySelected(prevQuantity => prevQuantity - 1)
                }
              >
                <img src={assets.minusIcon} />
              </Button>
              <span>{quantitySelected}</span>
              <Button
                variant="secondary"
                disabled={quantitySelected === quantity ? true : false}
                onClick={() =>
                  setQuantitySelected(prevQuantity => prevQuantity + 1)
                }
              >
                <img src={assets.plusIcon} />
              </Button>
            </div>
          </Col>
          <Col xs="6" md="4" className="foolter-col">
            <div className="add-to-cart-btn mt-4">
              <Button
                variant="secondary"
                disabled={loading}
                onClick={onClickAddToCart}
              >
                Add to cart
              </Button>
            </div>
          </Col>
        </>
      )}
    </Row>
  )
}
