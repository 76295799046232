import { BrowserRouter as Router } from "react-router-dom"
import AppRoutes from "./routes"
import AuthProvider from "./contexts/AuthContext"
import ErrorAlert from "./components/ErrorPopup"
import StoreProvider from "./contexts/StoreContext"
import ProductsProvider from "./contexts/ProductsContext"
import MapProvider from "./contexts/MapContext"
import CartProvider from "./contexts/CartContext"
import { PrivateRoute } from "./components/PrivateRoute"
import OrderProvider from "./contexts/OrderContext"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"
import "./App.scss"

function App() {
  return (
    <AuthProvider>
      <MapProvider>
        <StoreProvider>
          <ProductsProvider>
            <CartProvider>
              <OrderProvider>
                <Router>
                  <ErrorAlert />{/* ErrorAlert can be removed, as we will be using React Toastify instead */}
                  {/* <PrivateRoute> */}
                  <AppRoutes />
                  {/* </PrivateRoute> */}
                  <ToastContainer theme="colored" />
                </Router>
              </OrderProvider>
            </CartProvider>
          </ProductsProvider>
        </StoreProvider>
      </MapProvider>
    </AuthProvider>
  )
}

export default App
