import React, { useState } from "react"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom"
import "./AlertDismissible.scss"

export function ItemAddedToCartAlert({ show, setShow, message }) {
  if (show) {
    return (
      <Alert variant="info" onClose={() => setShow(false)} dismissible>
        <p className="text-center info-text">
          Item added to your cart! <Link to="/home/cart">View cart</Link>
        </p>
      </Alert>
    )
  }
  return <Button onClick={() => setShow(true)}>Show Alert</Button>
}
