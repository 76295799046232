import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { assets } from "../../utils"
import { useLocation, useNavigate } from "react-router-dom"
import "./VerifyUser.scss"

export const Onboarding = () => {
  const nav = useNavigate()
  const { state } = useLocation()
console.log("state",state)
  return (
    <Container className="Onboarding">
      <Row style={{ minHeight: "100vh" }}>
        <Col
          className="text-start pt-5 wrapper"
          style={{ margin: "auto" }}
          xs="11"
          lg="4"
        >
          <Row style={{ marginBottom: "8rem" }}>
            <Col xs="12" className="mb-3">
              <h2>We need to verify your identity</h2>
            </Col>
            <Col xs="12" className="mb-3">
              <p className="text-style">
                We’re required to collect certain information to help us know
                it’s actually you.
              </p>
            </Col>

            <Col xs="12" className="d-flex align-items-center mb-3">
              <img src={assets.circleCheckIcon} />
              <p className="text-style ms-3 mb-0">
                Tell us a bit more about yourself
              </p>
            </Col>
            <Col xs="12" className="d-flex align-items-center mb-3">
              <img src={assets.circleCheckIcon} />
              <p className="text-style ms-3 mb-0">
                Upload a photo of a goverment-issued ID
              </p>
            </Col>
            <Col xs="12" className="d-flex align-items-center mb-3">
              <img src={assets.circleCheckIcon} />
              <p className="text-style ms-3 mb-0">Take a selfie</p>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Button
                variant="dark"
                style={{ width: "100%" }}
                onClick={() =>
                  nav("/verify-user", {
                    state: { ...state }
                  })
                }
              >
                Continue
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
