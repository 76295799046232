import React from "react"
import { useState } from "react"
import { NavDropdown } from "react-bootstrap"
import { useAuthContext } from "../../contexts/AuthContext"
import { UserProfile } from "../../pages/UserProfile/UserProfile"
import { assets } from "../../utils"
import "./UserProfile.scss"

export default function UserProfileNavbar() {
  const { updateUserProfile } = useAuthContext()
  const [show, setShow] = useState(false)

  const onUpdateProfile = (loggedUserId, data) => {
    updateUserProfile(loggedUserId, data).then(() => setShow(false))
  }

  return (
    <NavDropdown
      title={<img src={assets.profileIcon} alt="icon-gear" />}
      show={show}
      onToggle={show => setShow(show)}
    >
      <UserProfile onUpdateProfile={onUpdateProfile} show={show} />
    </NavDropdown>
  )
}
