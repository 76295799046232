import React, { useEffect } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate, Link } from "react-router-dom"
import { assets, formatPhoneNumber } from "../../utils"
import { useCartContext } from "../../contexts/CartContext"

export const StoreDetail = ({
  logo,
  name,
  phone,
  address,
  distance,
  review_url,
  loading,
  id,
  storeDetails,
  clearProductContext,
  onClickFavouriteStore = () => { },
  onClickUnfavouriteStore = () => { },
  show
}) => {
  const nav = useNavigate()
  const { cartItemTotal, getCart } = useCartContext()
  useEffect(() => {
    // getCart()
  }, [storeDetails])
  return (
    <>
      {show ? (
        <div className="store-detail p-3">
          <Container>
            <Row>
              <Col
                xs="9"
                lg="10"
                className="text-start mb-3"
                onClick={() => {
                  clearProductContext()
                  nav("/home/stores")
                }}
              >
                <img
                  src={assets.backArrowWhite}
                  style={{ cursor: "pointer" }}
                />
              </Col>
              {id ? (
                <Col xs="2" lg="1" className="text-end mb-3">
                  <Button
                    className="favorite-btn"
                    disabled={loading}
                    onClick={() => onClickUnfavouriteStore(id)}
                  >
                    <img
                      src={assets.heartGold}
                      style={{
                        marginTop: "-8px"
                      }}
                    />
                  </Button>
                </Col>
              ) : (
                <Col xs="2" lg="1" className="text-end mb-3">
                  <Button
                    className="favorite-btn"
                    disabled={loading}
                    onClick={onClickFavouriteStore}
                  >
                    <img
                      src={assets.heart}
                      style={{
                        marginTop: "-8px"
                      }}
                    />
                  </Button>
                </Col>
              )}
              <Col xs="1" className="text-end mb-3 position-relative">
                <Link to="/home/cart">
                  <img src={assets.cartIconWhite} />
                  <div className="cart-item-count">
                    <span>{cartItemTotal}</span>
                  </div>
                </Link>
              </Col>
            </Row>
            <Row className="align-items-center">
              {logo ? (
                <Col xs="3" md="2" lg="1" className="store-logo">
                  <img src={logo} />
                </Col>
              ) : (
                <Col xs="3" md="2" lg="1" className="store-logo">
                  <img src={assets.storeDefaultIcon} />
                </Col>
              )}

              <Col xs="8" md="6" lg="6">
                <h6 className="name">{name}</h6>
                <p>
                  {distance &&
                    `${Number(distance).toFixed(1)} mi from your location`}{" "}
                </p>
                {review_url && (
                  <Link className="review_url" to={review_url} target="_blank">
                    Write a review
                  </Link>
                )}
                <p>{formatPhoneNumber(phone)} </p>
                <p>{address} </p>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="store-detail secondary-header p-3">
          <Container>
            <Row>
              <Col
                xs="2"
                lg="1"
                className="text-start mb-3"
                onClick={() => {
                  clearProductContext()
                  nav("/home/stores")
                }}
              >
                <img src={assets.backArrow} style={{ cursor: "pointer" }} />
              </Col>
              <Col
                xs="7"
                lg="9"
                className="text-start  mb-3 d-flex align-items-center justify-content-center"
              >
                <h6
                  className="name"
                  style={{ color: "#343434", fontWeight: "700" }}
                >
                  {name}
                </h6>
              </Col>
              {id ? (
                <Col xs="2" lg="1" className="text-end mb-3">
                  <Button
                    className="favorite-btn"
                    disabled={loading}
                    onClick={() => onClickUnfavouriteStore(id)}
                  >
                    <img src={assets.heartGold} />
                  </Button>
                </Col>
              ) : (
                <Col xs="2" lg="1" className="text-end mb-3">
                  <Button
                    className="favorite-btn"
                    disabled={loading}
                    onClick={onClickFavouriteStore}
                  >
                    <img src={assets.heartBlack} />
                  </Button>
                </Col>
              )}
              <Col xs="1" className="text-end mb-3 position-relative">
                <Link to="/home/cart">
                  <img src={assets.cartIcon} />
                  <div className="cart-item-count">
                    <span>{cartItemTotal}</span>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  )
}
