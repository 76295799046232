import React from 'react'
import { useCartContext } from '../../contexts/CartContext'
import '../MyOrderDetails/MyOrderDetails.scss';
import { assets } from '../../utils';
import { Order } from '../MyOrderDetails/Order';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ItemStore } from '../Cart/ItemStore';
import { useNavigate } from 'react-router-dom';

export default function MyProducts() {
    const { currentProductsPreview } = useCartContext()
    const nav = useNavigate()
    return (
        <Container className="my-order-details">
            <Row className="pt-3">
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute' }}>
                        <Button onClick={() => nav(-1)}>
                            <img src={assets.backArrow} />
                        </Button>
                    </div>
                    <div className="text-center mb-5 mt-1">
                        <p>Your Products</p>
                    </div>
                </div>
                <Col xs="12" lg="6">
                    <Row className="">
                        <ItemStore store={currentProductsPreview[0]?.productstock?.store} />
                    </Row>
                    <Row>
                        <Col>
                            <p className="your-cart-text">Your Products</p>
                        </Col>
                    </Row>
                    {currentProductsPreview.length && currentProductsPreview?.map(({ id, images, productstock, quantity }) => {
                        if (productstock) {
                            return (
                                <Order
                                    key={id}
                                    brand={productstock?.variation?.product?.brand}
                                    name={productstock?.variation?.product?.name}
                                    category={productstock?.variation?.product?.category}
                                    productVariation={productstock?.variation?.product?.name}
                                    image={images?.[0]?.image}
                                    price={productstock?.variation?.product?.price}
                                    quantity={quantity}
                                    parentScreen={'yourProducts'}
                                />
                            )
                        }
                    })}
                </Col>
            </Row>

            {/* <Loader loading={isLoading} /> */}
        </Container>
    )
}
