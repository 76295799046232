import React from "react"
import { Col, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import InputField from "../../components/InputField"
import { assets } from "../../utils"

export const InputSearch = ({ onChangeText = () => {} }) => {
  const { control, trigger } = useForm()

  return (
    <Row className="align-items-center">
      <Col xs="12" lg="5" className="position-relative">
        <InputField
          label=""
          name="searchText"
          placeholder="Search"
          control={control}
          trigger={trigger}
          className="input-search-box"
          onChange={({ target: { value } }) => onChangeText(value)}
        />
        <img
          src={assets.filterIcon}
          className="filter-icon"
          style={{ position: "absolute", top: "43px", right: "24px" }}
        />
      </Col>
    </Row>
  )
}
