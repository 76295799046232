import React from "react"
import { Col, Row } from "react-bootstrap"
import { assets } from "../../utils"
import "./Order.scss"

export const Order = ({
  brand = { id: "", name: "" },
  name = "- -",
  category = { id: '', name: "- -" },
  productVariation = "- -",
  price = "- -",
  image = "",
  quantity,
  parentScreen = ''
}) => {
  return (
    <>
      <Row className="align-items-center position-relative">
        <Col xs="2" lg="1">
          <img
            src={image || assets.productIcon}
            alt="icon"
            className="cart-item-img"
          />
        </Col>
        <Col xs="10" lg="11" className="ps-4 position-relative">
          <div className="brand-name-wrapper">
            {parentScreen === 'yourProducts' ?
              <span className="label-text">{`${brand?.name}`}</span> :
              <span className="label-text">{`${brand?.name} ${name}`}</span>}

            <span className="label-text ms-2">
              <img src={assets.smallCircle} />
              <span className="label-text ms-2">{category?.name}</span>
            </span>
          </div>
          <span className="flavour">{productVariation}</span>
          <p className={`price ${parentScreen === 'yourProducts' ? 'color-golden' : ''}`}>{`$${price}`}</p>
          <p className="quantity">{quantity}</p>
        </Col>
      </Row>
      <hr />
    </>
  )
}
