import React, { createContext, useContext, useEffect, useState } from "react"
import * as turf from "@turf/turf"
import { API } from "../api"
import { MAPBOX_TOKEN } from "../utils"

const MapContext = createContext()

const MapProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [locationAccess, setLocationAccess] = useState(false)
  const [currentLocation, setCurrentLocation] = useState({})
  const [currentPlaceName, setCurrentPlaceName] = useState("")

  const calculateDistance = position => {
    return new Promise(resolve => {
      const { latitude, longitude } = currentLocation
      const { lat, lng } = position
      if (latitude && longitude && lat && lng) {
        const from = turf.point([latitude, longitude])
        const to = turf.point([lat, lng])
        const options = { units: "miles" }
        const distance = turf.distance(from, to, options)
        resolve(distance)
      }
    })
  }

  const getPlaceName = (longitude, latitude) => {
    const URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`

    API.getPlaceName(URL).then(response =>
      setCurrentPlaceName(response.data.features[0].place_name)
    )
  }

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setCurrentLocation({
            ...currentLocation,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        },
        error => console.log(error)
      )
    } else {
      console.log("Geolocation is not supported by this browser.")
    }
  }

  useEffect(() => {
    const { latitude, longitude } = currentLocation
    if (latitude && longitude) {
      getPlaceName(longitude, latitude)
    }
  }, [currentLocation])

  useEffect(() => {
    //check user location changes in navigator
    navigator.permissions
      .query({ name: "geolocation" })
      .then(permissionStatus => {
        permissionStatus.onchange = () => {
          setLocationAccess(permissionStatus.state == "granted")
          
          if (permissionStatus.state == "granted") {
            getCurrentLocation()
          }
        }
      })
  }, [])

  return (
    <MapContext.Provider
      value={{
        isLoading,
        currentLocation,
        getCurrentLocation,
        setCurrentPlaceName,
        currentPlaceName,
        setCurrentLocation,
        calculateDistance,
        locationAccess
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

export const useMapContext = () => useContext(MapContext)

export default MapProvider
