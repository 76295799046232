import Card from "react-bootstrap/Card"
import { Button, Col, Row } from "react-bootstrap"
import { assets, formatPhoneNumber } from "../../utils"
import "./store.scss"

export function Store({ id, name, phone, address, distance, logo, is_favorite, onClick, markStoreAsFavorite,
  favStoreId, markStoreAsUnFavorite }) {
  return (
    <div className="store" onClick={() => onClick(id)}>
      <Card>
        <Card.Body>
          <Row className="card_header mb-2">
            <Col xs={2} className="my-auto mx-0">
              <img src={logo} alt="" />
            </Col>
            <Col xs={8}>
              <span className="name m-0">{name}</span>
            </Col>
            <Col xs={2} style={{ padding: '0' }}>
              {is_favorite ?
                <img className="store-favorite-icon" src={assets.heartFilledIcon}
                  onClick={(e) => {
                    markStoreAsUnFavorite(favStoreId)
                    e.stopPropagation()
                  }
                  } /> :
                <img className="store-favorite-icon" src={assets.heart}
                  onClick={(e) => {
                    markStoreAsFavorite(id)
                    e.stopPropagation()
                  }
                  } />}
            </Col>
          </Row>
          {distance && (
            <Card.Text className="distance">{`${Number(distance).toFixed(
              1
            )} mi from your location`}</Card.Text>
          )}
          <Card.Text className="textStyle">{formatPhoneNumber(phone)}</Card.Text>
          <Card.Text className="textStyle">{address}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}
