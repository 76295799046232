import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { assets } from "../../utils"
import "./MyOrders.scss"

export const Header = () => {
  const nav = useNavigate()
  return (
    <Row className="pt-4 p-2 cart-header">
      <Col xs="2" className="">
        <Button onClick={() => nav("/home/stores")}>
          <img src={assets.backArrow} />
        </Button>
      </Col>
      <Col xs="8" className="text-center">
        <p>My Orders</p>
      </Col>
    </Row>
  )
}
