import { post, get, put, del, patch } from "./http-methods"

export const API = {
  signup: payload => post("/api/v1/signup/", payload),
  login: payload => post("/api/v1/login/", payload),
  logout: () => post("/rest-auth/logout/"),
  emailOtp: payload => post("/api/v1/users/verify-account/send-mail/", payload),
  emailVerifyOtp: payload => post("/api/v1/users/verify-account/verify/", payload),
  forgotPassword: payload => post("/api/v1/users/forget-password/", payload),
  forgotPasswordVerify: payload =>
    post("/api/v1/users/forget-password/verify/", payload),
  forgotPasswordConfirm: payload =>
    post("/rest-auth/password/reset/confirm/", payload),
  getStores: config => get("/api/v1/stores/stores/", config),
  getStore: id => get(`/api/v1/stores/stores/${id}/`),
  getProducts: config => get("/api/v1/stores/customer/product/", config),
  getProduct: id => get(`/api/v1/stores/customer/product/${id}/`, id),
  getProductVariation: config =>
    get(`/api/v1/admin/product-variation/`, config),
  getProductVariationById: id => get(`/api/v1/admin/product-variation/${id}/`),

  getBrands: () => get("/api/v1/brand/"),
  getFlavours: config => get("/api/v1/flavour/", config),
  getFlavourTypes: () => get("/api/v1/flavourtype/"),
  getCategories: () => get("/api/v1/category/"),
  getCategory: id => get(`/api/v1/category/${id}/`),
  getPlaceName: url => get(url),
  getUserProfile: () => get(`/api/v1/users/my-profile/`),
  updateUserProfile: (id, payload) =>
    patch(`/api/v1/users/my-profile/`, payload),
  getFavouriteSku: () => get("/api/v1/favourites/"),
  createFavouriteSku: payload => post("/api/v1/favourites/", payload),
  deleteFavouriteSku: id => del(`/api/v1/favourites/${id}/`),

  getCart: () => get("/api/v1/cart/"),
  getCartItem: () => get("/api/v1/cart-item/"),
  createCartItem: payload => post("/api/v1/cart-item/", payload),
  updateCartItem: (id, payload) => patch(`/api/v1/cart-item/${id}/`, payload),
  deleteCartItem: id => del(`/api/v1/cart-item/${id}/`),
  getTnc: () => get("/api/v1/term-condition/"),
  getPrivacyPolicy: () => get("/api/v1/privacy-policy/"),
  deactivateAccount: id => del(`/api/v1/profile/${id}/`),
  getFavouriteStore: id =>
    id
      ? get(`/api/v1/stores/favourite-store/${id}/`)
      : get("/api/v1/stores/favourite-store/"),
  markStoreFavourite: payload =>
    post("/api/v1/stores/favourite-store/", payload),
  delFavouriteStore: id => del(`/api/v1/stores/favourite-store/${id}/`),
  getFeedbacks: url =>
    url ? get(`${url}`) : get("/api/v1/feedback/?limit=2&offset=2"),
  addFeedback: payload => post("/api/v1/feedback/", payload),
  notifyProductStock: id => post(`/api/v1/stores/stores/product-stock/${id}/notify/`),
  getOrderHistory: (id, config) =>
    id
      ? get(`/api/v1/order-history/${id}/`, config)
      : get(
        `/api/v1/order-history/?${config.params?.anotherStatus
          ? `status=${config.params.anotherStatus}`
          : ""
        }`,
        config
      ),
  checkoutOrder: payload => post("/api/v1/checkout/", payload),
  checkoutSuccessOrder: (uid, oid) =>
    get(`/api/v1/checkout/success/${uid}/${oid}/`),
  markOrderFavourite: (id, payload) =>
    patch(`/api/v1/order-history/${id}/`, payload),
  orderAgain: id => post(`/api/v1/order/${id}/reorder/`),
  updateDeviceId: payload => patch(`/api/v1/users/device-token/update/`, payload),

  getInventory: (store_id, variation_id) =>
    get(`/api/v1/stores/stores/${store_id}/check-inventory/${variation_id}/ `),
  curbsideNotify: (storeId) => post(`/api/v1/notify/${storeId}/store/`),
  getDealForAProuct: (productId) => get(`/api/v1/get-deals/${productId}/`),
  markAProductAsFavorite: (inventoryId) => post(`/api/v1/stores/favourite-product/${inventoryId}/`),
  markAProductAsUnFavorite: (inventoryId) => del(`/api/v1/stores/favourite-product/${inventoryId}/`),
  getFlavorProfile: () => get(`/api/v1/variation/?search=Flavor%20Profile`),
  getVariationValuseById: (id) => get(`/api/v1/variation-value/`,{params:{variation:id}}),
  getInventoryIdForCompProduct: (config) => get(`/api/v1/stores/customer/product/`,config),
  getBestSellers: (config) => get(`/api/v1/customer/best-seller/products/`,config),

}
