import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import InputField from "../../components/InputField"
import { validation } from "../../utils"
import { CommonAuthScreen } from "../CommonAuthScreen"
import { ButtonGolden } from "../../components/ButtonGolden"
import { useAuthContext } from "../../contexts/AuthContext"
import "./verifyEmail.scss"

export function VerifyEmail() {
  const nav = useNavigate()
  const location = useLocation()
  const { isLoading, emailOtp } = useAuthContext()
  const { control, trigger, setValue, handleSubmit } = useForm()

  const onSubmit = async vals => {
    emailOtp(vals).then(() =>
      nav("/otp-verification", {
        state: vals
      })
    )
  }

  useEffect(() => {
    if (location.state?.email) {
      setValue("email", location.state?.email)
    }
  })

  return (
    <CommonAuthScreen
      showLogo={false}
      title={<p style={{ marginTop: "2.5rem" }}>Verify your Email </p>}
      subTitle={
        <>
          We’ll email you a token to verify your email
        </>
      }
    >
      <form className={"signup"} onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col xs={12} className="mb-3">
            <InputField
              label="Email"
              name="email"
              placeholder=""
              rules={{
                required: "Email required!",
                validate: { ...validation.email }
              }}
              compType=""
              control={control}
              trigger={trigger}
              disabled={true}
              className="applicationScreenInput"
            />
          </Col>

          <Col xs={12}>
            <ButtonGolden label="Send" disabled={isLoading} />
          </Col>
        </Row>
      </form>
    </CommonAuthScreen>
  )
}
