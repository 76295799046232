import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { InputPassword } from "../../components/InputPassword/InputPassword"
import { CommonAuthScreen } from "../CommonAuthScreen"
import { ButtonGolden } from "../../components/ButtonGolden"
import { useAuthContext } from "../../contexts/AuthContext"
import { validatePassword } from "../../utils/Validation"
import { validation } from "../../utils"
import { ALL_ROUTES } from "../../app-config"

import "./setNewPassword.scss"

export function SetNewPassword() {
  const nav = useNavigate()
  const location = useLocation()
  const { isLoading, forgotPasswordConfirm } = useAuthContext()

  const {
    control,
    register,
    trigger,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    handleSubmit
  } = useForm()

  const password = watch("password")
  const confirmPassword = watch("confirm_password")
  const [token, setToken] = useState("")
  const [uid, setUid] = useState("")

  const onSubmit = async vals => {
    const { password, confirm_password } = vals
    const payload = {}
    payload.token = token
    payload.uid = uid
    payload.new_password1 = password
    payload.new_password2 = confirm_password
    console.log(payload)
    forgotPasswordConfirm(payload).then(() => nav(ALL_ROUTES.login))
  }

  useEffect(() => {
    if (password) {
      setError("password", {
        type: "custom",
        message: validatePassword(password)
      })
    }
    if (confirmPassword) {
      setError("confirm_password", {
        type: "custom",
        message: validatePassword(confirmPassword)
      })
    }

    if (confirmPassword) {
      if (password) {
        if (confirmPassword !== password) {
          setError("confirm_password", {
            type: "custom",
            message: "Passwords do not match"
          })
        } else {
          clearErrors("confirm_password")
        }
      }
    }
  }, [password, confirmPassword])

  useEffect(() => {
    console.log("location", location.state)
    if (location.state) {
      const { token, uid } = location.state
      setToken(token)
      setUid(uid)
    }
  }, [])

  return (
    <CommonAuthScreen
      showLogo={false}
      title={<p style={{ marginTop: "2.5rem" }}>Set a new password </p>}
      subTitle={
        <>
          Password must be at least 8 characters — including 1 uppercase, 1
          lowercase letter, 1 number and 1 special character.
        </>
      }
    >
      <form className={"signup"} onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col xs={12}>
            <InputPassword
              label="Password"
              register={register}
              control={control}
              trigger={trigger}
              rules={{
                required: "Password required!",
                validate: validation.password
              }}
              name="password"
              placeholder=""
              error={errors["password"]}
              className="applicationScreenInput"
            />
          </Col>
          <Col xs={12} className="mb-3">
            <InputPassword
              label="Confirm Password"
              register={register}
              control={control}
              trigger={trigger}
              rules={{
                required: "Confirm Password required!",
                validate: (value) => value === watch("password") || "Passwords do not match"
              }}
              name="confirm_password"
              placeholder=""
              error={errors["confirm_password"]}
              className="applicationScreenInput"
            />
          </Col>

          <Col xs={12}>
            <ButtonGolden
              label="Log in"
              disabled={(password !== confirmPassword) | isLoading}
            />
          </Col>
        </Row>
      </form>
    </CommonAuthScreen>
  )
}
