import React from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { assets } from "../../utils"
import "./products.scss"

export const Filters = ({
  searchText,
  filterLabels = [],
  onClickHandle,
  onChangeHandle
}) => {
  return (
    <Container>
      <div className="row filters-wrapper">
        <Col xs="12" lg="3" className="me-auto">
          <Form.Group className="search-wrapper">
            <Form.Control
              defaultValue={searchText}
              className="search-box"
              placeholder="Search"
              onChange={onChangeHandle}
            />
            <img src={assets.search} />
          </Form.Group>
        </Col>
      </div>
      <Row className="chip pb-1 mx-0" style={{flexWrap:'wrap'}}>
        {filterLabels.map(({ label, active }) => (
          <Col xs="3" md={2} lg={1} key={label}>
            <Button
              variant="outline-secondary"
              onClick={() => onClickHandle(label)}
              className={`${active ? "active" : ""}`}
            >
              {label}
            </Button>
          </Col>
        ))}
      </Row>
    </Container>
  )
}
