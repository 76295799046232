import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { assets } from "../../utils"
import { useNavigate } from "react-router-dom"
import "./FavouriteStores.scss"

export const FavouriteStores = ({ stores, isUserFlagged }) => {
  const nav = useNavigate()
  return (
    <div className={`stores ${isUserFlagged ? "pointer-events" : ""}`}>
      <Row className="mb-1 align-items-center mb-3">
        <Col xs="11">
          <span className="favorite-vsu-text">Your favorite VSU locations</span>
        </Col>
        <Col xs="1">
          <img src={assets.forwardArrow} className="mb-1" />
        </Col>
      </Row>
      <Row className="favourite-store-wrapper no-scrollbar flex-nowrap">
        {stores.length > 0 ? (
          stores.map(({ id, store, store_name, distance }) => (
            <Col xs="5" md="3" lg="2" key={id} className="favourite-store">
              <Card
                style={{ width: "9rem", height: "3.5rem" }}
                onClick={() =>
                  nav(`/home/store/${store}`, {
                    state: { id }
                  })
                }
              >
                <Card.Body>
                  <Row className="card_header mb-2">
                    <Col xs="12">
                      <Card.Text className="store-name mb-1">
                        {store_name}
                      </Card.Text>
                    </Col>
                    <Col xs="12">
                      <Card.Text className="distance">
                        {`${distance?Number(distance).toFixed(1):'- -'} mi `}
                        <img src={assets.heartSmaller} />
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col className="text-center no-data-found-text">
            <p>No data found</p>
          </Col>
        )}
      </Row>
      {isUserFlagged && <div className="flagged-overlay"></div>}
    </div>
  )
}
