import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { assets } from "../../utils"
import "./Cart.scss"

export const Header = () => {
  const nav = useNavigate()
  return (
    <Row className="pt-4 p-2 cart-header">
      <Col xs="2" md="1" lg="1" xl="1">
        <img
          src={assets.backArrow}
          className="arrow-back-icon"
          onClick={() => nav(-1)}
        />
      </Col>

      <Col xs="8" md="11" lg="11" xl="11" className="text-center">
        <p>My cart</p>
      </Col>
    </Row>
  )
}
