import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"
import { formatDateForDB } from "../utils"


const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [loggedUserId, setLoggedUserId] = useState("")
  const [isUserFlagged, setIsUserFlagged] = useState(false)
  const [content, setContent] = useState("")
  const [tncRead, setTncRead] = useState(false)
  const [ppRead, setPpRead] = useState(false)
  const [showTnc, setShowTnc] = useState(false)
  const [showPp, setShowPp] = useState(false)
  const [feedbacks, setFeedbacks] = useState({
    results: [],
    next: ""
  })
  const [verification, setVerication] = useState({
    firstName: "",
    lastName: "",
    date_of_birth: ""
  })

  const userSignup = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.signup(payload)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const userLogin = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.login(payload)
        .then(response => {
          const { payload } = response.data
          localStorage.setItem("token", payload.token)
          localStorage.setItem("user", JSON.stringify(payload.user))
          resolve(payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const userLogout = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.logout(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => console.log(error))
        .finally(() => {
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          setIsLoading(false)
          resolve()
        })
    })
  }

  const emailOtp = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.emailOtp(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const emailVerifyOtp = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.emailVerifyOtp(payload)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const forgotPassword = payload => {
    setIsLoading(true)
    return new Promise((resolve,reject) => {
      API.forgotPassword(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error =>{console.log(error)
          reject(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const forgotPasswordVerify = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.forgotPasswordVerify(payload)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const forgotPasswordConfirm = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.forgotPasswordConfirm(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  /** USER PROFILE API */

  const getLoggedInUser = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user?.id) {
      setLoggedUserId(user.id)
    }
  }

  const getUserProfile = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      console.log('[getUserProfile]')
      API.getUserProfile(id)
        .then(response => {
          resolve({ ...response.data.payload, ...response.data.payload.user })
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const updateUserProfile = (id, payload) => {
    console.log("updateUserProfile")
    const formData = new FormData()
    Object.keys(payload).forEach(key => {
      if (payload[key] && key === "date_of_birth") {
        return formData.append(key, formatDateForDB(payload[key]))
      }
      if (payload[key] && key === "phone_number") {
        return formData.append(key, setCountryCode(payload[key]))
      }
      if (payload[key] && key === "name") {
        return formData.append("user", JSON.stringify({ name: payload[key] }))
      }
      //countryCode
      formData.append(key, payload[key])
    })
    setIsLoading(true)
    return new Promise(resolve => {
      API.updateUserProfile(id, formData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getTnc = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getTnc()
        .then(response => {
          setContent(response.data.payload.content)
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getPrivacyPolicy = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getPrivacyPolicy()
        .then(response => {
          setContent(response.data.payload.content)
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deactivateAccount = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.deactivateAccount(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  /** Feedback API */
  const getFeedbacks = url => {
    setIsLoading(true)
    API.getFeedbacks(url)
      .then(response => {
        const { results, next } = response.data
        setFeedbacks(prevState => ({
          ...prevState,
          results: [...prevState.results, ...results],
          next
        }))
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const addFeedback = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addFeedback(payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const setCountryCode = phone_number => {
    const countryCode = localStorage.getItem("countryCode")
    if (countryCode) {
      return `${countryCode}${phone_number}`
    } else {
      return `+1${phone_number}`
    }
  }

  useEffect(() => {
    localStorage.setItem("countryCode", "+91")
    if (loggedUserId) {
      console.log('[AuthProvider useEffect] getUserProfile')
      getUserProfile(loggedUserId).then(response =>
        setIsUserFlagged(response.flag)
      )
    }
  }, [loggedUserId])

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userLogin,
        userLogout,
        userSignup,
        emailOtp,
        emailVerifyOtp,
        forgotPassword,
        forgotPasswordVerify,
        forgotPasswordConfirm,
        loggedUserId,
        getLoggedInUser,
        getUserProfile,
        updateUserProfile,
        isUserFlagged,
        content,
        setContent,
        getTnc,
        getPrivacyPolicy,
        tncRead,
        ppRead,
        setTncRead,
        setPpRead,
        showTnc,
        showPp,
        setShowTnc,
        setShowPp,
        deactivateAccount,
        feedbacks,
        getFeedbacks,
        addFeedback,
        verification,
        setVerication
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
