import React from "react"
import Card from "react-bootstrap/Card"
import { Link } from "react-router-dom"
import { assets } from "../../utils"
import "./CommonAuthScreen.scss"
import { Container } from "react-bootstrap"
import { ALL_ROUTES } from "../../app-config"

export const CommonAuthScreen = ({
  showLogo = true,
  isLogin = false,
  title = "",
  subTitle = "",
  linkTo = ALL_ROUTES.signup,
  children
}) => {
  return (
    <Container
      fluid
      style={{ background: "#343434", height: "100vh", overflowY: "auto" }}
    >
      <div className="commonAuthScreen pwa">
        <Card
          style={{ width: "375px", color: '#fff' }}
          className={`${isLogin ? "login-card" : ""}`}
        >
          {!showLogo && (
            <Link to={linkTo}>
              <img
                src={assets.backArrow}
                alt="back arrow"
                className="card-back-arrow"
              />
            </Link>
          )}
          {showLogo && <Card.Img variant="top" src={assets.logo} />}
          <Card.Body>
            <Card.Title as="h4">{title}</Card.Title>
            {!showLogo && (
              <Card.Text className="text-center" style={{ fontSize: "18px" }}>
                {subTitle}
              </Card.Text>
            )}
            {children}
          </Card.Body>
        </Card>
      </div>
    </Container>
  )
}
