import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"

const CartContext = createContext()

const CartProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [cartId, setCartId] = useState("")
  const [cartTotal, setCartTotal] = useState(0)
  const [cartItems, setCartItems] = useState([])
  const [cartItemTotal, setCatrtItemTotal] = useState(0)
  const [showAvailableQauntity, setShowAvailableQauntity] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showItemAddedAlert, setShowItemAddedAlert] = useState(false)
  const [cartPriceDetails, setCartPriceDetails] = useState({})
  const [groupedStoreAndCart, setGroupedStoreAndCart] = useState({})
  const [currentProductsPreview, setCurrentProductsPreview] = useState({})
  const [pickupOptions, setPickupOptions] = useState([
    {
      label: "Store Pick Up",
      value: "1",
      checked: true
    },
    {
      label: "Curbside",
      value: "2",
      checked: false
    }
  ])

  const onChangePickupType = value => {
    const newOptions = pickupOptions.map(option => ({
      ...option,
      checked: false
    }))
    const index = newOptions.findIndex(option => option.value === value)
    newOptions[index].checked = true
    setPickupOptions(newOptions)
  }

  const calculateCartDetails = (cartItems, totalPrice) => {
    const obj = {
      subTotal: 0,
      salesTax: 0,
      nicotineTax: 0,
      discount:0,
      totalPrice: 0
    }
    obj.subTotal = totalPrice
    cartItems.forEach(item=>{
      obj.salesTax += item.total_sales_tax
      obj.nicotineTax += item.total_nicotine_tax
      obj.discount += item.discount
    })
    obj.salesTax = Number(obj.salesTax.toFixed(2))
    obj.nicotineTax = Number(obj.nicotineTax.toFixed(2))
    obj.discount = Number(obj.discount.toFixed(2))
    obj.totalPrice = (obj.subTotal +  obj.salesTax + obj.nicotineTax).toFixed(2)
    obj.priceToPay = Number((obj.totalPrice) - obj.discount).toFixed(2)
    setCartPriceDetails(obj)
  }

  const getCart = () => {
    setIsLoading(true)
    API.getCart()
      .then(response => {
        const { id, user, cart_items, total_price} = response.data.payload
        setCartItems(cart_items)
        setCartId(id)
        calculateCartDetails(cart_items,total_price)
        // setCartTotal(response.data?.total_price)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getCartItem = () => {
    setIsLoading(true)
    API.getCartItem()
      .then(response => setCartItems(response.data))
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const createCartItem = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.createCartItem(payload)
        .then(response => resolve(response.data))
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const updateCartItem = (id, payload) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.updateCartItem(id, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteCartItem = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.deleteCartItem(id)
        .then(response => resolve(response.data))
        .catch(error => setIsLoading(false))
        .finally(() => setIsLoading(false))
    })
  }

  const addItemToCart = item => {
    const index = cartItems.findIndex(
      ({ productstock }) => productstock.id === item.productstock
    )
    if (index === -1) {
      // cart item create API
      const newItem = { ...item, cart: cartId }
      createCartItem(newItem).then(() => {
        getCart()
        setShowItemAddedAlert(true)
        setTimeout(() => setShowItemAddedAlert(false), 3000)
      })
      return
    }

    // cart update API
    const stockQuantity = cartItems[index]?.productstock?.quantity
    const quantityRequested = cartItems[index].quantity + item?.quantity
    item.quantity = quantityRequested
    item.cart = cartItems[index].cart
    const idToUpdate = cartItems[index].id

    if (quantityRequested > stockQuantity) {
      setShowItemAddedAlert(false)
      setShowAlert(true)
      return setTimeout(() => setShowAlert(false), 3000)
    } else {
      updateCartItem(idToUpdate, item).then(() => {
        getCart()
        setShowItemAddedAlert(true)
        setTimeout(() => setShowItemAddedAlert(false), 3000)
      })
    }
  }

  const checkoutOrder = payload => {
    setIsLoading(true)
    return new Promise((resolve,reject) => {
      API.checkoutOrder(payload)
        .then(response => {
          setCartItems([])
          setCartTotal(0)
          resolve(response.data)
        })
        .catch(error => {reject(error);setIsLoading(false)})
        .finally(() => setIsLoading(false))
    })
  }

  const checkoutSuccessOrder = ({ uid, oid }) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.checkoutSuccessOrder(uid, oid)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => setIsLoading(false))
        .finally(() => setIsLoading(false))
    })
  }

  useEffect(() => {
    const totalCount = cartItems.reduce(function (acc, obj) {
      return acc + obj.quantity
    }, 0)
    setCatrtItemTotal(totalCount)
  }, [cartItems])

  return (
    <CartContext.Provider
      value={{
        isLoading,
        getCart,
        cartItems,
        getCartItem,
        createCartItem,
        updateCartItem,
        deleteCartItem,
        addItemToCart,
        cartItemTotal,
        cartTotal,
        showAvailableQauntity,
        showAlert,
        setShowAlert,
        showItemAddedAlert,
        setShowItemAddedAlert,
        pickupOptions,
        onChangePickupType,
        checkoutOrder,
        checkoutSuccessOrder,
        cartPriceDetails,
        setGroupedStoreAndCart,
        groupedStoreAndCart,
        currentProductsPreview,
        setCurrentProductsPreview
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCartContext = () => useContext(CartContext)

export default CartProvider
