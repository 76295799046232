import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-bootstrap-typeahead/css/Typeahead.css"
import "react-bootstrap-typeahead/css/Typeahead.bs5.css"
import { useEffect } from "react"
import { assets, MAPBOX_TOKEN } from "../../utils"
import { MyLocationDetails } from "../MyLocationDetails/MyLocationDetails"
import { useMapContext } from "../../contexts/MapContext"
import { Link, useNavigate } from "react-router-dom"
import "./searchableMap.scss"

export function SearchableMap() {
  const nav = useNavigate()
  const { currentPlaceName, setCurrentLocation } = useMapContext()
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState([])
  const [confirmChange, setConfirmChange] = useState(false)

  const onClickHandle = () => {
    console.log(selected)
    if (selected[0]) {
      const { center } = selected[0]
      const position = { latitude: center[1], longitude: center[0] }
      setCurrentLocation(position)
    }
    setConfirmChange(false)
    nav("/home/stores")
  }

  useEffect(() => {
    if (selected[0]) {
      setConfirmChange(true)
    }
  }, [selected])

  return (
    <Container className="search-map">
      <Row className="mb-3 mt-4">
        <Col xs="1">
          <Link to="/home/stores">
            <img src={assets.backArrow} />
          </Link>
        </Col>
        <Col>
          <h5 className="text-center pt-1">Change address</h5>
        </Col>
        <Col xs={1}></Col>
      </Row>
      <div>
        <img src={assets.search} alt="search-icon" className="search-icon" />
        <AsyncTypeahead
          id="mapbox-id"
          isLoading={isLoading}
          labelKey={option => `${option.place_name}`}
          onSearch={query => {
            console.log("query", query)
            setIsLoading(true)
            fetch(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_TOKEN}`
            )
              .then(resp => resp.json())
              .then(json => {
                setOptions(json.features)
                setIsLoading(false)
              })
          }}
          options={options}
          onChange={setSelected}
          placeholder="search address"
          className="search-address-input"
        />
      </div>
      {confirmChange && (
        <MyLocationDetails
          placeName={selected[0]?.place_name}
          onClickHandle={onClickHandle}
          setConfirmChange={setConfirmChange}
        />
      )}
    </Container>
  )
}
