import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { assets } from "../../utils"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import "./ProductDetails.scss"

const Description = ({
  product,
  groupedVariants,
  onClickNicotineChange,
  relatedProducts,
  variations,
  variationObj,
  selectedKey,
  setSelectedKey,
  productVariationID,
  setProductVariationID,
  productAttributes,
  setPvID,
  getDealForAProuct,
  navigateCompatible
}) => {
  const nav = useNavigate()
  const { name, brand, price, category, milligram, description } = product || {}
  const [variants, setVariants] = useState([])
  const [variantSelected, setVariantSelected] = useState([])
  const [dealMessage, setDealMessage] = useState('')
  const [selectedProductvaritioDetail, setSelectedProductvaritioDetail] = useState('')

  useEffect(() => {
    const itemSelected = variants.find(({ checked }) => checked) || {}
    onClickNicotineChange(itemSelected)
  }, [variants])

  useEffect(() => {
    if (groupedVariants.length) {
      const { value } = groupedVariants.filter(({ checked }) => checked)[0]
      setVariants(value)
    }
  }, [groupedVariants])

  useEffect(() => {
    if (product?.id) {
      getDealForAProuct(product?.id).then(res => {
        if (res.status && res.status === 'Active') {
          setDealMessage(`Sale: ${res.message}`)
        }
      }).catch(err=>{
        setDealMessage('')
      })
    }
  }, [product])

  useEffect(() => {
    Object.keys(variationObj).map(key => {
      if (variationObj[key].selected) {
        setPvID(variationObj[key]?.pvID)
        setProductVariationID(variationObj[key].values?.[0]?.cid)
        setVariantSelected(variationObj[key].values)
      }
    })
  }, [variationObj])

  useEffect(()=> {
    Object.keys(variationObj).map(key => {
      if (variationObj[key].selected) {
        if (variationObj[key].value !== 'DEFAULT') {
          const selectedVariation = variationObj[key].value
            const selectedProductVariant = variationObj[key].values.find(el=>el.cid===productVariationID)?.variationvalue.value;
            const name = selectedProductVariant ? `${selectedVariation} - ${selectedProductVariant}`:`${selectedVariation}`
          setSelectedProductvaritioDetail(name)
        }else {
          setSelectedProductvaritioDetail('')
        }
      }
    })
  },[variationObj,productVariationID])


  return (
    <Row className="Description">
      <Col xs="12">
        {brand?.name && <span className="label-text">{`${brand.name} `}</span>}
        {name && <span className="label-text">{`${name} `}</span>}
        {milligram?.gram > 0 && (
          <span className="label-text">{`${milligram.gram}ml`}</span>
        )}
        {category?.name && (
          <span className="label-text ms-2">
            <img src={assets.smallCircle} />
            <span className="label-text ms-2">{category.name}</span>
          </span>
        )}
      </Col>
      <Col xs="12" className="variation-details">
        {selectedProductvaritioDetail}
      </Col>
      <Col
        xs="12"
        className="d-flex align-items-center justify-content-between"
      >
        {price && (
          <span className="price">{`$${Number(price).toFixed(2)}`}</span>
        )}
        {!!dealMessage.length && <span className="deal-message">{dealMessage}</span>}
      </Col>
      <Col xs="12">
        <hr />
      </Col>

      <Col xs="12" className="">
        {productAttributes.map(({ id, name, value }) => (
          <div className="d-flex align-items-center" key={id}>
            <p className="label-text">{`${name}:`}</p>
            <p className="label-text ms-1">{value}</p>
          </div>
        ))}
      </Col>
      <Col xs="12" className="">
        {description && (
          <>
            <p className="label-text">Description</p>
            <p className="nicotine-strength">{description}</p>
          </>
        )}
      </Col>
      <Col className="variation-wrapper mb-3">
        {variations?.[0]?.variation?.name && (
          <p className="label-text">{`${variations?.[0]?.variation?.name}:`}</p>
        )}
        <div className="d-flex flex-wrap gap-3">
          {Object.values(variationObj).sort((a,b)=>a.value>b.value?1:-1).map(val => {
            const variation = val
            const key = val.id
            return (
              variation.value !== "DEFAULT" && <div
                key={key}
                className={`variant-chip ${variation.selected ? "selected" : ""
                  }`}
                onClick={() => setSelectedKey(key)}
              >
                <span>{variation.value}</span>
              </div>
            )
          })}
        </div>
      </Col>

      <Col className="variation-wrapper mb-3">
        {variations?.[1]?.variation?.name && (
          <p className="label-text">{`${variations?.[1]?.variation?.name}:`}</p>
        )}
        <div className="d-flex flex-wrap gap-3">
          {variantSelected.map(({ id, cid, variationvalue }) => (
            <div
              key={id}
              className={`variant-chip ${productVariationID === cid ? "selected" : ""
                }`}
              onClick={() => setProductVariationID(cid)}
            >
              <span>{variationvalue.value}</span>
            </div>
          ))}
        </div>
      </Col>
      {relatedProducts.length > 0 && (
        <Col xs="12" className="">
          <p className="label-text label-flavours">Related</p>
        </Col>
      )}
      <Col xs="12" className="d-flex related-items-wrapper no-scrollbar">
        {[...relatedProducts].map(
          ({
            id,
            product: {
              brand,
              variation: { images }
            }
          }) => (
            <div
              key={id}
              className="d-flex flex-column related-item"
              onClick={() => {
                if (id) {
                  const url = {}
                  url.pathname = "/home/product-details/"
                  url.search = `?id=${id}`
                  nav(url)
                }
              }}
            >
              <img src={images[0]?.image} style={{ width: "56px", height: "48px" }} />
              <span style={{ fontSize: "0.5rem", textAlign: "center" }}>
                {brand?.name}
              </span>
            </div>
          )
        )}
      </Col>
      {!!product?.compatible_products.length && <Col xs="12" className="d-flex related-items-wrapper no-scrollbar compatible-products-wrapper">
        <div style={{ marginTop: '5px' }}><p className="label-text label-flavours" style={{ margin: '0' }}>Compatible Products</p></div>
        <div className="comp-prod-chip">{product?.compatible_products.map(({ id, name }) =>
          <span
              onClick={()=>navigateCompatible(id)}
            className="product-chip"
          // onClick={() => {
          //   if (id) {
          //     const url = {}
          //     url.pathname = "/home/product-details/"
          //     url.search = `?id=${id}`
          //     nav(url)
          //   }
          // }}
          >
            {name}
          </span>
        )}</div>
      </Col>}
    </Row>
  )
}

export default React.memo(Description)
