import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useCartContext } from "../../contexts/CartContext"
import { assets } from "../../utils"
import { useProductsContext } from "../../contexts/ProductsContext"
import "./Cart.scss"

export const CartItem = ({
  // brand = { id: "", name: "" },
  brand = "",
  name = "",
  category = { id: "", name: "" },
  flavour = { name: "" },
  price = "",
  image = "",
  stockQuantity = 1, // actual availble stock quantity
  quantity = 1, // quantity requested by user,
  cart,
  productstock,
  id,
  variationId,
  onClickQuantityChange,
  onDeleteCartItem,
  is_under_deal = false,
  discount
}) => {
  const { getCartItemsProductVariationById } = useProductsContext()
  const { isLoading } = useCartContext()
  const [quantitySelected, setQuantitySelected] = useState(0)
  const [variationText, setVariationText] = useState('')

  useEffect(() => {
    setQuantitySelected(quantity)
  }, [quantity])

  // fetch variation value
  useEffect(() => {
    getCartItemsProductVariationById(variationId).then(resp=>{
      const productVariations = resp?.productvariations
      const variationValues = []
      if(productVariations.length) {
        productVariations.forEach(variation=>{
          let val = '';
          if(variation.variationvalue?.id && variation.variationvalue?.value!=='DEFAULT'){
          val = `${variation.variationvalue?.value}`
          variationValues.push(val)
          }
        })
      }
      setVariationText(variationValues.join(' - '))
    })
  }, [])
  return (
    <>
      <Row className="align-items-center position-relative">
        <Col xs="2">
          <img src={image} className="cart-item-img" />
        </Col>
        <Col xs="10" className="ps-4">
          <div className="cart-item brand-name-wrapper">
            <span className="label-text">{`${brand.name} | ${name}`}</span>
            <span className="label-text ms-2">
              <img src={assets.smallCircle} />
              <span className="label-text ms-2">{category?.name}</span>
            </span>
          </div>
          <p className="cart-item-variation">{variationText}</p>
          <div className="d-flex align-items-center justify-content-between">
            <p className="price">{`$${Number(price).toFixed(2)}`}</p>
            <div>
              <Button
                variant="secondary"
                disabled={(quantitySelected === 1 ? true : false) || isLoading}
                onClick={() => {
                  // setQuantitySelected(prevQuantity => prevQuantity - 1)
                  const payload = {}
                  payload.cart = cart
                  payload.productstock = productstock
                  payload.quantity = quantity - 1
                  onClickQuantityChange(id, payload)
                }}
              >
                <img src={assets.minusIcon} />
              </Button>
              <span className="quantitySelected">{quantitySelected}</span>
              <Button
                variant="secondary"
                disabled={isLoading}
                onClick={() => {
                  const payload = {}
                  payload.cart = cart
                  payload.productstock = productstock
                  payload.quantity = quantity + 1
                  onClickQuantityChange(id, payload)
                }}
              >
                <img src={assets.cartPlusIcon} />
              </Button>
              <Button
                variant="secondary"
                disabled={isLoading}
                onClick={() => {
                  onDeleteCartItem(id)
                }}
              >
                <img src={assets.cartDeleteIcon} />
              </Button>
            </div>
          </div>
          {discount>0 && <span className="deal-applied">Deal Applied</span>}
        </Col>
      </Row>
      <hr />
    </>
  )
}

export const CartItems = ({
  items,
  onClickQuantityChange = () => { },
  onDeleteCartItem = () => { }
}) => {
  return items.map(({ cart, id, images, total_price, productstock, quantity, is_under_deal, discount }) => {
    const item = {}
    item.cart = cart
    item.id = id
    item.productstock = productstock?.id

    if (productstock?.variation) {
      const {
        flavour,
        id,
        product: { name, brand, category, price }
      } = productstock.variation

      item.flavour = flavour
      item.price = total_price
      item.quantity = quantity
      item.image = images?.[0]?.image
      item.name = name
      item.brand = brand
      item.category = category
      item.variationId = id
      item.is_under_deal = is_under_deal
      item.discount = discount
    }

    if (productstock?.quantity) {
      item.stockQuantity = productstock.quantity
    }

    return (
      <div key={id}>
        <CartItem
          {...item}
          onClickQuantityChange={onClickQuantityChange}
          onDeleteCartItem={onDeleteCartItem}
        />
      </div>
    )
  })
}
