import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../components/Loader"
import { useCartContext } from "../../contexts/CartContext"
import { useProductsContext } from "../../contexts/ProductsContext"
import { assets } from "../../utils"
import "./ProductDetails.scss"

export const Header = ({ id, loggedUserId, storeId }) => {
  const nav = useNavigate()
  const { productsku, getProductSku, createFavouriteSku, deleteFavouriteSku } =
    useProductsContext()
  const { cartItemTotal } = useCartContext()
  const { favourite } = productsku
  const [loading, setLoading] = useState(false)
  const onClickFavourite = productsku => {
    setLoading(true)
    const payload = {}
    payload.user = loggedUserId
    payload.productsku = productsku
    createFavouriteSku(payload).then(() => {
      getProductSku(id).then(() => setTimeout(() => setLoading(false), 300))
    })
  }
  const onClickDeleteFavourite = favouriteId => {
    setLoading(true)
    deleteFavouriteSku(favouriteId).then(() => {
      getProductSku(id).then(() => setTimeout(() => setLoading(false), 500))
    })
  }

  useEffect(() => {
    if (id) {
      getProductSku(id)
    }
  }, [id])

  return (
    <Row className="pt-4 p-2 Header">
      <Col xs="8" md="10" lg="10" xl="10">
        <img
          src={assets.backArrow}
          className="arrow-back-icon"
          onClick={() => {
            if (storeId) {
              nav(`/home/store/${storeId}`)
            } else {
              nav(-1)
            }
          }}
        />
      </Col>

      <Col
        xs="4"
        md="1"
        lg="1"
        xl="1"
        className="d-flex align-items-center justify-content-end"
      >
        {/* {favourite?.is_favourite ? (
          <Button
            className="heart-btn"
            onClick={e => {
              e.stopPropagation()
              if (favourite?.is_favourite) {
                return onClickDeleteFavourite(favourite?.id)
              }
            }}
            disabled={loading}
          >
            <img src={assets.heartFilledIcon} />
          </Button>
        ) : (
          <Button
            className="heart-btn"
            onClick={e => {
              e.stopPropagation()
              onClickFavourite(productsku.productsku?.id)
            }}
            disabled={loading}
          >
            <img src={assets.heartBlack} />
          </Button>
        )} */}
        <div className="cart-icon-wrapper" onClick={() => nav("/home/cart")}>
          <img src={assets.cartIcon} />
          <div className="cartItemTotal">
            <span>{cartItemTotal}</span>
          </div>
        </div>
      </Col>
      {/* <Col xs="2" md="1" lg="1" xl="1">
        <div className="cart-icon-wrapper" onClick={() =>nav("/home/cart")}>
          <img src={assets.cartIcon} />
          <div className="cartItemTotal">
            <span>{cartItemTotal}</span>
          </div>
        </div>
      </Col> */}
      <Loader loading={loading} />
    </Row>
  )
}
