import React from "react"
import { Col, Row } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./ProductDetails.scss"

export const ImageSlider = ({ images = [] }) => {
  const settings = {
    dots: true,
    arrows: false
  }
  return (
    <Row className="ImageSlider pt-4 p-2 mb-5">
      <Col
        xs="12"
        className="position-relative"
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          height: "240px"
        }}
      >
        {images.length > 0 ? (
          <Slider {...settings}>
            {[...images].map(({ image }, index) => (
              <div key={index}>
                <img className="m-auto" src={image} />
              </div>
            ))}
          </Slider>
        ) : (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            No picture available
          </p>
        )}
      </Col>
    </Row>
  )
}
