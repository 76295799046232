import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import InputField from "../../components/InputField"
import { validation } from "../../utils"
import { CommonAuthScreen } from "../CommonAuthScreen"
import { ButtonGolden } from "../../components/ButtonGolden"
import { useAuthContext } from "../../contexts/AuthContext"
import { createCustomErrorMessage } from "../../api/http-methods"
import { ALL_ROUTES } from "../../app-config"

import "./forgotPasswordVerify.scss"

export function ForgotPasswordVerify() {
  const nav = useNavigate()
  const location = useLocation()
  const { isLoading, forgotPasswordVerify, emailOtp } = useAuthContext()
  const { control, trigger, handleSubmit } = useForm()
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)

  const onSubmit = async vals => {
    const payload = {}
    payload.email = email
    payload.otp = vals.otp
    setLoading(true)
    forgotPasswordVerify(payload)
      .then(response => {
        nav("/set-new-password", {
          state: response
        })
      })
      .catch((error) => {
        createCustomErrorMessage(error)
        // window?.customErrorCallback?.(error?.response?.data?.error, error?.response?.status)
        setLoading(false)
      })
  }

  const resendCode = () => {
    if (email) {
      setResendLoading(true)
      emailOtp({ email })
        .then(() => setResendLoading(false))
        .catch(() => setResendLoading(false))
    }
  }

  useEffect(() => {
    setEmail(location.state?.email)
  }, [])

  return (
    <CommonAuthScreen
      showLogo={false}
      title={<p style={{ marginTop: "2.5rem" }}>Token verification </p>}
      subTitle={
        <>
          Please enter the token verification code sent <br /> to
          <Link to={`mailto:${email}`}>{email}</Link>
        </>
      }
      linkTo={ALL_ROUTES.verifyEmail}
    >
      <form className={"signup"} onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col xs={12} className="mb-3">
            <InputField
              label=""
              name="otp"
              placeholder=""
              rules={{
                required: "OTP required!",
                validate: { ...validation.number }
              }}
              compType=""
              control={control}
              trigger={trigger}
              className="applicationScreenInput"
            />
          </Col>
          <Col xs={12} className="text-center mb-3">
            <Link onClick={resendCode}>
              {resendLoading ? "Resending code..." : "Resend code"}
            </Link>
          </Col>
          <Col xs={12}>
            <ButtonGolden label="Verify" disabled={loading || resendLoading} />
          </Col>
        </Row>
      </form>
    </CommonAuthScreen>
  )
}
