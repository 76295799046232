import React from "react"
import { Col, Navbar, NavDropdown, Row } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { assets } from "../../utils"
import "./NavbarSettings.scss"

export default function NavbarSettings({ userLogout = () => {}, userName }) {
  const nav = useNavigate()

  return (
    <Navbar.Text className="homepage-settings">
      <NavDropdown title={<img src={assets.iconGear} alt="icon-gear" />}>
        <NavDropdown.Item disabled className="text-dark">
          <Row>
            <Col className="col-8">
              <h6>Information</h6>
            </Col>
          </Row>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item className="text-dark">
          <Link to="/home/feedback" className="text-decoration-none">
            <Row>
              <Col xs="10">
                <p>Support & Feedback</p>
              </Col>
              <Col xs="2">
                <img src={assets.forwardArrow} />
              </Col>
            </Row>
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item className="text-dark">
          <Link to="/home/my-orders" className="text-decoration-none">
            <Row>
              <Col xs="10">
                <p>My orders</p>
              </Col>
              <Col xs="2">
                <img src={assets.forwardArrow} />
              </Col>
            </Row>
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Divider />
      </NavDropdown>
    </Navbar.Text>
  )
}
