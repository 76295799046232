const vouchedScriptTagId = "vouched"

const getVouchedScriptTag = () => document.getElementById(vouchedScriptTagId)

export const loadVouched = config => {
  const existingScript = getVouchedScriptTag()
  if (!existingScript) {
    const script = document.createElement("script")
    script.src = "https://static.vouched.id/widget/vouched-2.0.0.js"
    script.id = vouchedScriptTagId
    script.async = true
    script.onload = () => {
      window.vouchedObject = window["Vouched"]({ ...config })
      console.log("[VOUCHED SCRIPT] (loadVouched) Mounting on element #vouched-element")
      console.log("[VOUCHED SCRIPT] (loadVouched) Config:", config)
      console.log("[VOUCHED SCRIPT] (loadVouched) vouched object:", window.vouchedObject)
      setTimeout(() => {
        const existingScriptTag = getVouchedScriptTag()
        if(!!existingScriptTag) {
          console.log(existingScriptTag)
          window.vouchedObject.mount("#vouched-element")
        }
      }, 1200)
    }
    document.head.appendChild(script)
  }
}

export const removeVouchedScript = () => {
  console.log('[removeVouchedScript]')

  // programatically unmount vouched
  window?.vouchedObject?.unmount("#vouched-element")
  
  // remove script
  const existingScript = getVouchedScriptTag()
  if(!!existingScript) {
    console.log('[removeVouchedScript] removing script tag from DOM')
    existingScript.parentNode.removeChild(existingScript)
    delete window["Vouched"]
  }
}
