import React from "react"
import { useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import InputField from "../../components/InputField"
import { validation } from "../../utils"
import { CommonAuthScreen } from "../CommonAuthScreen"
import { ButtonGolden } from "../../components/ButtonGolden"
import { useAuthContext } from "../../contexts/AuthContext"
import { createCustomErrorMessage } from "../../api/http-methods"
import { ALL_ROUTES } from "../../app-config"

import "./resetPassword.scss"

export function ResetPassword() {
  const nav = useNavigate()
  const { isLoading, forgotPassword } = useAuthContext()
  const { control, trigger, handleSubmit } = useForm()

  const onSubmit = async vals => {
    forgotPassword(vals).then(() =>
      nav("/forgot-password-verify", {
        state: vals
      })
    )
    .catch((error) => {
      createCustomErrorMessage(error)
      // window?.customErrorCallback?.(error?.response?.data?.error, error?.response?.status)
    })
  }

  return (
    <CommonAuthScreen
      showLogo={false}
      title={<p style={{ marginTop: "2.5rem" }}>Reset your password </p>}
      subTitle={
        <>
          We’ll email you a token to reset your <br /> password
        </>
      }
      linkTo={ALL_ROUTES.login}
    >
      <form className={"signup"} onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col xs={12} className="mb-3">
            <InputField
              label="Email"
              name="email"
              placeholder=""
              rules={{
                required: "Email required!",
                validate: { ...validation.email }
              }}
              compType=""
              control={control}
              trigger={trigger}
              className="applicationScreenInput"
            />
          </Col>

          <Col xs={12}>
            <ButtonGolden label="Send" disabled={isLoading} />
          </Col>
        </Row>
      </form>
    </CommonAuthScreen>
  )
}
