import { Button, Col, Container, Row } from "react-bootstrap"
import { Order } from "./Order"
import { assets } from "../../utils"
import { useOrderContext } from "../../contexts/OrderContext"
import { useProductsContext } from "../../contexts/ProductsContext"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { Loader } from "../../components/Loader"
import { ItemStore } from "../Cart/ItemStore"
import "./MyOrderDetails.scss"

export const MyOrderDetails = () => {
  const nav = useNavigate()
  const { isLoading, order, getOrder } = useOrderContext()
  const { getCartItemsProductVariationById } = useProductsContext()
  const { id } = useParams()
  const [orderItems, setOrderItems] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [taxInfo, setTaxInfo] = useState({})

  useEffect(() => {
    if (order?.order_items) {
      calculateTaxesAndDiscount(order?.order_items, order?.total_discount)
      getVariationNames(order?.order_items)
      // setOrderItems(order.order_items)
    }
    if (order?.total_price) {
      setTotalPrice(order.total_price)
    }
  }, [order])

  const getVariationNames = (orderItems) => {
    let promises = [];
    orderItems.map(el=>{
      promises.push(getCartItemsProductVariationById(el.product).then(r => ({ id: el.id, result: r })));
    })
    Promise.all(promises).then(results => {
      let lookup = results.reduce((prev, curr) => {
        prev[curr.id] = curr.result;
        return prev;
      }, {});
      for(const key in lookup){
        let variationName = createVariationName(lookup[key].productvariations)
        lookup[key]['variationName'] = variationName
      }
      let neworderItems = [...orderItems]
      neworderItems = neworderItems.map(el=>{
        const obj = {...el}
        obj['variationName'] = lookup[obj.id].variationName;
        return obj
      })
      setOrderItems(neworderItems)
    })
  }

  // create a variation name
  const createVariationName = (productvariations) =>{
      const productVariations = productvariations
      const variationValues = []
      if (productVariations.length) {
        productVariations.forEach(variation => {
          let val = '';
          if (variation.variationvalue?.id && variation.variationvalue?.value !== 'DEFAULT') {
            val = `${variation.variationvalue?.value}`
            variationValues.push(val)
          }
        })
      }
      return variationValues.join(' - ') || ''
    }

  useEffect(() => {
    if (id) {
      getOrder(id)
    }
  }, [id])

  const calculateTaxesAndDiscount = (orders, discount = 0) => {
    let obj = {
      salesTax: 0,
      nicotineTax: 0,
      discount: +Number(discount).toFixed(2)
    }
    orders.map(order => {
      obj.salesTax += +Number(order?.product_detail?.total_sales_tax).toFixed(2)
      obj.nicotineTax += +Number(order?.product_detail?.total_nicotine_tax).toFixed(2)
    })
    setTaxInfo(obj)
  }

  return (
    <Container className="my-order-details">
      <Row className="pt-3">
        <div style={{position:'relative'}}>
        <div style={{position:'absolute'}}>
          <Button onClick={() => nav("/home/my-orders")}>
            <img src={assets.backArrow} />
          </Button>
        </div>
        <div className="text-center mb-5 mt-1">
          <p>Orders details</p>
        </div>
        </div>
        <Col xs="12" lg="6">
          <Row className="">
            <ItemStore store={order?.store} />
          </Row>
          <Row>
            <Col>
              <p className="your-cart-text">Products</p>
            </Col>
          </Row>
          {orderItems?.map(({ id, product_detail, quantity, variationName }) => {
            if (product_detail?.productstock) {
              const { productstock, images } = product_detail
              return (
                <Order
                  key={id}
                  brand={productstock?.variation?.product?.brand}
                  name={productstock?.variation?.product?.name}
                  category={productstock?.variation?.product?.category}
                  productVariation = {variationName}
                  image={images?.[0]?.image}
                  price={productstock?.variation?.product?.price}
                  quantity={quantity}
                />
              )
            }
          })}
        </Col>
        <Col xs="12" lg="1"></Col>
        <Col xs="12" lg="5">
          <Row className="price-section">
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Subtotal:</p>
              <p>{`$ ${Number(totalPrice).toFixed(2)}`}</p>
            </Col>
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Tax:</p>
              <p>{`$ ${taxInfo.salesTax}`}</p>
            </Col>
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Cecet Tax:</p>
              <p>{`$ ${taxInfo.nicotineTax}`}</p>
            </Col>
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Discount:</p>
              <p>{`$${taxInfo.discount}`}</p>
            </Col>
            <Col
              xs="12"
              lg="8"
              className="price-item d-flex align-items-center justify-content-between"
            >
              <p>Promo Code:</p>
              <p>$ 0.0</p>
            </Col>
            <hr />
            <Col
              xs="12"
              lg="8"
              className="total-price-item d-flex align-items-center justify-content-between"
            >
              <p>{`Total (${orderItems.length} items):`}</p>
              <p>{`$ ${+Number(totalPrice).toFixed(2) + taxInfo.salesTax + taxInfo.nicotineTax - taxInfo.discount}`}</p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Loader loading={isLoading} />
    </Container>
  )
}
