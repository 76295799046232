import Card from "react-bootstrap/Card"
import { assets } from "../../utils"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import "./product.scss"

export function Product({
  id,
  product,
  flavour,
  img,
  productskuId,
  is_favourite,
  loading,
  productDetail,
  onClickFavourite = () => {},
  onClickDeleteFavourite = () => {},
  storeId
}) {
  const nav = useNavigate()
  const { name, brand, price } = product
  return (
    <div
      className="product"
      onClick={() => {
        if (id) {
          const url = {}
          url.pathname = "/home/product-details/"
          url.search = `?id=${id}`
          nav(url, {
            state: {
              storeId
            }
          })
        }
      }}
    >
      <Card>
        <Card.Body>
          <div className="card_header text-center">
            <img src={img} alt="" />
            {is_favourite ? (
              <Button
                className="heart-btn"
                onClick={e => {
                  e.stopPropagation()
                  if (is_favourite) {
                    return onClickDeleteFavourite(id)
                  }
                }}
                disabled={loading}
              >
                <img src={assets.heartFilledIcon} />
              </Button>
            ) : (
              <Button
                className="heart-btn"
                onClick={e => {
                  e.stopPropagation()
                  onClickFavourite(id)
                }}
                disabled={loading}
              >
                <img src={assets.heart} />
              </Button>
            )}
          </div>
          <Card.Text className="desc text-center">{`${brand?.name}`}</Card.Text>
          <Card.Text className="name">{name}</Card.Text>
          <Card.Text className="price">{`$${Number(price).toFixed(
            2
          )}`}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}
