import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"
import {
  FILTER_LABELS,
  MILLIGRAM_OPTIONS,
  NICOTINE_OPTIONS,
  PUFF_COUNT_OPTIONS,
  SORT_BY_OPTIONS
} from "../pages/Products/filterData"
import {
  loadFromLocalStorage,
  saveToLocalStorage
} from "../utils/localStorageHelper"

const ProductsContext = createContext()

const ProductsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState({ results: [], next: null })
  const [product, setProduct] = useState({
    id: "",
    productsku: {},
    other_variants: [],
    quantity: "",
    store: {}
  })
  const [otherVariants, setOtherVariants] = useState([])
  const [brands, setBrands] = useState([])
  const [flavors, setFlavors] = useState([])
  const [flavours, setFlavours] = useState([])
  const [flavourTypes, setFlavourTypes] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [productsku, setProductSku] = useState({})
  const [sortByOptions, setSortByOptions] = useState([])
  const [puffCountOptions, setPuffCountOptions] = useState([])
  const [milligramOptions, setMilligramOptions] = useState([])
  const [nicotineOptions, setNicotineOptions] = useState([])
  const [filterLabels, setFilterLabels] = useState([])
  const [initialLoad, setInitialLoad] = useState(true)

  const [filterParams, setFilterParams] = useState({})
  const [searchText, setSearchText] = useState("")
  const [category, setCategory] = useState({})
  const [productVariations, setProductVariations] = useState([])
  const [defaultProductVariation, setDefaultProductVariation] = useState({})

  const getProducts = config => {
    setIsLoading(true)

    return new Promise(resolve => {
      API.getProducts(config)
        .then(response => {
          const { results, next } = response.data.payload

          if (config.params.offset == 0) {
            return setProducts({
              results: results,
              next: next
            })
          }
          setProducts(prevState => ({
            results: [...prevState.results, ...results],
            next: next
          }))
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getProduct = id => {
    setIsLoading(true)
    API.getProduct(id)
      .then(response => {
        // console.log(response.data.productsku)
        setProduct(response.data.payload)
        // setOtherVariants(response.data.other_variants)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getBestSellers = storeId => {
    setIsLoading(true)
    const config = { params: { store: storeId } }
    return new Promise((resolve) => {
      API.getBestSellers(config)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getProductVariation = config => {
    setIsLoading(true)
    API.getProductVariation(config)
      .then(response => {
        const { results } = response.data.payload
        setProductVariations(results)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getProductVariationById = id => {
    setIsLoading(true)
    API.getProductVariationById(id)
      .then(response => {
        setDefaultProductVariation(response.data.payload)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getCartItemsProductVariationById = id => {
    return new Promise(resolve => {
      API.getProductVariationById(id)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }
  const getRelatedProducts = (config) => {
    return new Promise(resolve => {
      API.getProducts(config)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getProductSku = id => {
    setIsLoading(true)

    return new Promise(resolve => {
      API.getProduct(id)
        .then(response => {
          setProductSku(response.data)
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getInventory = (store_id, variation_id) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getInventory(store_id, variation_id)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getBrands = () => {
    setIsLoading(true)
    API.getBrands()
      .then(response =>
        setBrands(
          response.data.payload.results.map(({ name, id }) => ({
            label: name,
            value: id,
            id: id,
            checked: false
          }))
        )
      )
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getflavorProfileData = () => {
    API.getFlavorProfile().then(resp => {
      const id = resp.data.payload.results?.[0].id
      API.getVariationValuseById(id).then(resp => {
        setFlavors(
          resp.data.payload?.results.map(({ id, variation, value }) => ({
            label: value,
            value: id,
            id: id,
            checked: false
          }))
        )
      })
    })
  }

  const getFlavours = config => {
    setIsLoading(true)
    API.getFlavours(config)
      .then(response =>
        setFlavours(
          response.data.results.map(({ name }) => ({
            label: name,
            value: name,
            checked: false
          }))
        )
      )
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getFlavourTypes = () => {
    setIsLoading(true)
    API.getFlavourTypes()
      .then(response =>
        setFlavourTypes(
          response.data.results.map(({ name }) => ({
            label: name,
            value: name,
            checked: false
          }))
        )
      )
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getCategories = () => {
    setIsLoading(true)
    API.getCategories()
      .then(response =>
        setCategories(
          response.data.payload.results.map(({ id, name, sub_category }) => ({
            label: name,
            value: name,
            id: id,
            checked: false,
            sub_category
          }))
        )
      )
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getCategory = id => {
    setIsLoading(true)
    API.getCategory(id)
      .then(response => setCategory(response.data.payload))
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getFavouriteSku = () => {
    setIsLoading(true)
    API.getFavouriteSku()
      .then(response => console.log("getFavouriteSku", response))
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const createFavouriteSku = payload => {
    setIsLoading(true)

    return new Promise(resolve => {
      API.createFavouriteSku(payload)
        .then(response => resolve(response.data))
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }
  const markAProductAsFavorite = id => {
    setIsLoading(true)

    return new Promise(resolve => {
      API.markAProductAsFavorite(id)
        .then(response => resolve(response.data))
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }
  const markAProductAsUnFavorite = id => {
    setIsLoading(true)

    return new Promise(resolve => {
      API.markAProductAsUnFavorite(id)
        .then(response => resolve(response.data))
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteFavouriteSku = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.deleteFavouriteSku(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  /** Filters Handlers */

  const onChangeBrand = (value, id) => {
    const update = [...brands]
    const index = update.findIndex(item => item.id == id)
    update[index].checked = !update[index].checked
    setBrands(update)
  }
  const onChangeFlavor = (value, id) => {
    const update = [...flavors]
    const index = update.findIndex(item => item.id == id)
    update[index].checked = !update[index].checked
    setFlavors(update)
  }

  const onResetFlavors = () => {
    setFlavors(prevState => prevState.map(item => ({ ...item, checked: false })))
    const newFilterParams = { ...filterParams }
    delete newFilterParams.classificationvalue__in
    setFilterParams(newFilterParams)
  }

  const onResetBrand = () => {
    setBrands(prevState => prevState.map(item => ({ ...item, checked: false })))
    const newFilterParams = { ...filterParams }
    delete newFilterParams.brand
    setFilterParams(newFilterParams)
  }

  const onResetSortBy = () => {
    setSortByOptions(prevState =>
      prevState.map(item => ({ ...item, checked: false }))
    )
    const newFilterParams = { ...filterParams }
    delete newFilterParams.ordering
    setFilterParams(newFilterParams)
  }

  const resetAllOptions = () => {
    setBrands(prevState => prevState.map(item => ({ ...item, checked: false })))
    setCategories(prevState =>
      prevState.map(item => ({ ...item, checked: false }))
    )
    setSubCategories([])
  }

  const onChangeSortBy = value => {
    const newOptions = sortByOptions.map(item => ({ ...item, checked: false }))
    const index = newOptions.findIndex(({ label }) => label === value)
    newOptions[index].checked = true
    setSortByOptions(newOptions)
  }

  const onChangeCategory = (value, id) => {
    console.log(value, id)
    const newOptions = categories.map(item => ({
      ...item,
      checked: false
    }))
    const index = newOptions.findIndex(({ label }) => label === value)
    newOptions[index].checked = true
    setCategories(newOptions)
    setSubCategories(
      categories
        .find(category => category.id == id)
        .sub_category.map(({ id, name, parent }) => ({
          label: name,
          value: name,
          id: id,
          parent: parent,
          checked: false
        }))
    )
  }

  const onSelectSubCategory = (value, id) => {
    const update = [...subCategories]
    const index = update.findIndex(item => item.id == id)
    update[index].checked = !update[index].checked
    setSubCategories(update)
  }

  const onResetCategory = () => {
    setCategories(prevState =>
      prevState.map(item => ({ ...item, checked: false }))
    )
    setSubCategories([])
    const newFilterParams = { ...filterParams }
    delete newFilterParams.category
    setFilterParams(newFilterParams)
  }

  const notifyProductStock = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.notifyProductStock(id)
        .then(response => resolve(response))
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getDealForAProuct = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getDealForAProuct(id)
        .then(response => resolve(response.data.payload))
        .catch(error => {
          reject(error)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getInventoryIdForCompProduct = config => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getInventoryIdForCompProduct(config)
        .then(response => resolve(response.data.payload))
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  useEffect(() => {
    const config = {}
    config.params = {}
    const flavourtype = flavourTypes.find(({ checked }) => checked)?.value
    if (flavourtype) {
      config.params.flavourtype__name = flavourtype
      getFlavours(config)
    }
  }, [flavourTypes])

  useEffect(() => {
    setSortByOptions(SORT_BY_OPTIONS)
    setPuffCountOptions(PUFF_COUNT_OPTIONS)
    setMilligramOptions(MILLIGRAM_OPTIONS)
    setNicotineOptions(NICOTINE_OPTIONS)
    setFilterLabels(FILTER_LABELS)
  }, [])

  const getInitialLoadItems = () => {
    if (initialLoad) {
      getBrands()
      getCategories()
      getflavorProfileData()
      setInitialLoad(false)
    }
  }

  const restoreGlobalState = prevState => {
    const { brands, flavours, flavourTypes, categories } = prevState
    setBrands(brands.map(item => ({ ...item, checked: false })))
    setFlavours(flavours.map(item => ({ ...item, checked: false })))
    setFlavourTypes(flavourTypes.map(item => ({ ...item, checked: false })))
    setCategories(categories.map(item => ({ ...item, checked: false })))
  }

  useEffect(() => {
    window.onbeforeunload = () => {
      saveToLocalStorage("filters", {
        brands,
        flavours,
        flavourTypes,
        categories
      })
    }

    const prevState = loadFromLocalStorage("filters")
    if (!prevState) {
      saveToLocalStorage("filters", {
        brands,
        flavours,
        flavourTypes,
        categories
      })
    }

    if (prevState) {
      restoreGlobalState(prevState)
    }
    return () => {
      window.onbeforeunload = null
      saveToLocalStorage("filters")
    }
  }, [brands, flavours, flavourTypes, categories])

  const clearProductContext = () => {
    setProducts({ results: [], next: null });
    setProduct({
      id: "",
      productsku: {},
      other_variants: [],
      quantity: "",
      store: {}
    })
  }

  return (
    <ProductsContext.Provider
      value={{
        isLoading,
        products,
        getProducts,
        setProducts,
        product,
        otherVariants,
        getProduct,
        brands,
        getBrands,
        flavours,
        getFlavours,
        flavourTypes,
        getFlavourTypes,
        categories,
        getCategories,
        getFavouriteSku,
        createFavouriteSku,
        deleteFavouriteSku,
        productsku,
        getProductSku,
        filterParams,
        setFilterParams,
        onChangeBrand,
        onResetBrand,
        onChangeCategory,
        onResetCategory,
        searchText,
        setSearchText,
        filterLabels, // look into it to arrange it
        setFilterLabels,
        notifyProductStock,
        category,
        getCategory,
        productVariations,
        getProductVariation,
        defaultProductVariation,
        getProductVariationById,
        setProductVariations,
        setCategory,
        getInventory,
        subCategories,
        onSelectSubCategory,
        resetAllOptions,
        getCartItemsProductVariationById,
        getDealForAProuct,
        getRelatedProducts,
        markAProductAsFavorite,
        markAProductAsUnFavorite,
        sortByOptions,
        onChangeSortBy,
        onResetSortBy,
        onChangeFlavor,
        flavors,
        onResetFlavors,
        clearProductContext,
        getInitialLoadItems,
        getInventoryIdForCompProduct,
        getBestSellers
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}

export const useProductsContext = () => useContext(ProductsContext)

export default ProductsProvider
