import React, { useEffect, useState, useRef } from "react"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useStoreContext } from "../../contexts/StoreContext"
import { useProductsContext } from "../../contexts/ProductsContext"
import { Product } from "../Product/Product"
import { StoreDetail } from "./StoreDetail"
import { Filters } from "./Filters"
import { assets, debounce, getOffset } from "../../utils"
import { FiltersBottomDrawer } from "../../components/FiltersBottomDrawer"
import { Loader } from "../../components/Loader"
import { useMapContext } from "../../contexts/MapContext"
import { useAuthContext } from "../../contexts/AuthContext"
import InfiniteScroll from "react-infinite-scroller"
import { BestSeller } from "../Product/BestSeller"
import { useDetectScrolledToBottom } from "../../utils/useDetectScrolledToBottom"
import "./products.scss"

export function Products({ }) {
  const params = useParams()
  const { loggedUserId, getLoggedInUser } = useAuthContext()
  const { currentLocation } = useMapContext()
  const {
    isLoading: storeLoading,
    store,
    getStore,
    favoriteStores,
    getFavouriteStores,
    markStoreFavourite,
    delFavouriteStore
  } = useStoreContext()
  const {
    isLoading,
    products,
    getProducts,
    brands,
    categories,
    createFavouriteSku,
    deleteFavouriteSku,
    filterParams,
    setFilterParams,
    onChangeBrand,
    onChangeFlavor,
    onResetFlavors,
    flavors,
    onResetBrand,
    onChangeCategory,
    onResetCategory,
    filterLabels,
    setFilterLabels,
    searchText,
    setSearchText,
    setProducts,
    subCategories,
    onSelectSubCategory,
    resetAllOptions,
    markAProductAsFavorite,
    markAProductAsUnFavorite,
    sortByOptions,
    onChangeSortBy,
    onResetSortBy,
    clearProductContext,
    getBestSellers
  } = useProductsContext()

  const [showBrand, setShowBrand] = useState(false)
  const [showSortBy, setShowSortBy] = useState(false)
  const [showFlavorProfile, setShowFlavorProfile] = useState(false)
  const [showCategory, setShowCategory] = useState(false)
  const [markingFavourite, setMarkingFavourite] = useState(false)
  const [favouriteId, setFavouriteId] = useState("")
  const [next, setNext] = useState("")
  const [showBestSellers, setShowBestSellers] = useState(false)
  const [filterType, setFilterType] = useState("")
  const [bestSellers, setBestSellers] = useState({})

  const node = useRef()
  const { isBottom } = useDetectScrolledToBottom(node)

  const onChangeHandle = debounce(({ target }) => {
    const { value } = target
    setSearchText(value)
  }, 300)

  const onSrollToBottom = debounce(() => {
    setShowBestSellers(!isBottom)
  }, 300)

  const onClickFilterType = type => {
    setFilterType(type)
  }

  const hideFilter = () => {
    setFilterType("")
    setShowBrand(false)
    setShowCategory(false)
    setShowSortBy(false)
    setShowFlavorProfile(false)
  }

  const loadProducts = (offset = 0) => {
    if (params?.storeId) {
      const config = {
        params: {
          limit: 20,
          offset: offset,
          store: params.storeId,
          q: searchText,
          ...filterParams
        },
        paramsSerializer: {
          indexes: null
        }
      }
      getProducts(config)
    }
  }

  const onApplyFilters = type => {
    const isTypeAvailable = Object.keys(filterParams).find(key =>
      key.includes(type)
    )

    const newFilterLabels = [...filterLabels]

    if (isTypeAvailable) {
      const index = newFilterLabels.findIndex(({ value }) => value === type)
      newFilterLabels[index].active = true
    } else {
      const index = newFilterLabels.findIndex(({ value }) => value === type)
      newFilterLabels[index].active = false
    }

    const index = newFilterLabels.findIndex(({ value }) => value === "all")

    if (!Object.keys(filterParams).length) {
      newFilterLabels[index].active = true
    } else {
      newFilterLabels[index].active = false
    }

    setFilterLabels(newFilterLabels)
    hideFilter()
    loadProducts()
  }

  /** TOGGLE FAVOURITE */
  const onClickFavourite = (id) => {
    const payload = {}
    payload.user = loggedUserId
    payload.product = id
    markAProductAsFavorite(id).then(response => {
      if (response?.payload?.success) {
        const update = [...products.results]
        const index = update.findIndex(item => item.id == id)
        update[index].is_favourite = true
        setProducts({ results: update, next: products.next })
      }
    })
  }

  const onClickDeleteFavourite = (id) => {
    markAProductAsUnFavorite(id).then(() => {
      const update = [...products.results]
      const index = update.findIndex(item => item.id == id)
      update[index].is_favourite = false
      setProducts({ results: update, next: products.next })
    })
  }

  /** FAVOURITE AND UNFAVOURITE STORE */
  const onClickFavouriteStore = () => {
    console.log("onClickFavouriteStore", params)
    if (params?.storeId) {
      markStoreFavourite({ store: params.storeId }).then(() =>
        getFavouriteStores()
      )
    }
  }
  const onClickUnfavouriteStore = id => {
    delFavouriteStore(id).then(() => getFavouriteStores())
  }

  useEffect(() => {
    console.log("filterType", filterType)
    if (filterType === "All") {
      setFilterLabels(
        filterLabels.map(item => ({
          ...item,
          active: item.label == filterType ? true : false
        }))
      )
      setFilterParams({})
      resetAllOptions()
    } else if (filterType === "Brand") {
      setShowBrand(true)
    } else if (filterType === "Category") {
      setShowCategory(true)
    } else if (filterType === "Sort By") {
      setShowSortBy(true)
    } else if (filterType === "Flavor") {
      setShowFlavorProfile(true)
    }
  }, [filterType])

  useEffect(() => {
    let newFilterParams = { ...filterParams }
    const brandsSelected = brands
      .filter(({ checked }) => checked)
      .map(({ id }) => id)
    const subCategoriesSelected = subCategories
      .filter(({ checked }) => checked)
      .map(({ id }) => id)

    const categorySelected = categories
      .filter(({ checked }) => checked)
      .map(({ id }) => id)

    const sortBySelected = sortByOptions
      .filter(({ checked }) => checked)
      .map(({ value }) => value)

    const flavorsSelected = flavors
      .filter(({ checked }) => checked)
      .map(({ value }) => value)
    if (brandsSelected.length) {
      newFilterParams.brand = brandsSelected
    } else {
      delete newFilterParams.brand
    }
    if (subCategoriesSelected.length) {
      newFilterParams.category = subCategoriesSelected
    } else if (categorySelected.length){
      newFilterParams.category = categorySelected
    }
      if (sortBySelected.length) {
        newFilterParams.ordering = sortBySelected
      }
    if (flavorsSelected.length) {
      newFilterParams.classificationvalue__in = flavorsSelected.join(',')
    } else {
      delete newFilterParams.classificationvalue__in
    }

    setFilterParams(newFilterParams)
  }, [brands, subCategories, sortByOptions, flavors])

  useEffect(() => {
    loadProducts()
  }, [searchText])

  useEffect(() => {
    getStore(params.storeId)
    getBestSellers(params.storeId).then(res=>{
      setBestSellers(res)
    })
  }, [])

  useEffect(() => {
    if (params?.storeId && filterType === "All") {
      const config = {
        params: {
          limit: 20,
          offset: 0,
          store: params.storeId,
          q: searchText
        },
        paramsSerializer: {
          indexes: null
        }
      }
      getProducts(config)
    }
  }, [params?.storeId, filterType])

  useEffect(() => {
    getLoggedInUser()
  }, [])

  useEffect(() => {
    if (store?.id) {
      getFavouriteStores()
    }
  }, [store.id])

  useEffect(() => {
    const itemFound = favoriteStores.find(
      ({ store: storeId }) => storeId == params?.storeId
    )
    if (itemFound?.id) {
      setFavouriteId(itemFound?.id)
    } else {
      setFavouriteId("")
    }
  }, [favoriteStores])

  const loadMoreHandler = () => {
    if (products.next) {
      return setNext(products.next)
    } else {
      // sethasMoreValues(false)
    }
  }

  useEffect(() => {
    if (next) {
      const offset = getOffset(next)
      loadProducts(offset)
    }
  }, [next])

  useEffect(() => {
    onSrollToBottom()
  }, [isBottom])

  const getProductDetails = (productVariations) => {
    const detailsArr = productVariations.map(variation => {
      let val = '';
      if (variation.variationvalue?.id && variation.variationvalue?.value !== 'DEFAULT') {
        val = `${variation.variationvalue?.value}`
        return val
      }
      return ''
    })
    return detailsArr.filter(el => el).join(' - ')
  }

  const renderProducts = () => {
    if (!products.results.length)
      return <p className="no-products-found text-center">No data found</p>

    return [...products.results].map(
      ({
        product: {
          brand,
          category,
          description,
          name,
          price,
          variation: { images, productvariations }
        },
        id,
        is_favourite
      }) => {
        const productDetail = getProductDetails(productvariations)
        return <Col xs="6" lg="3" className="mb-4" key={id}>
          <Product
            id={id}
            product={{ brand, name, price }}
            // flavour={flavour?.name}
            img={images?.[0]?.image}
            productDetail={productDetail}
            // productskuId={productskuId}
            is_favourite={is_favourite}
            loading={markingFavourite}
            onClickFavourite={onClickFavourite}
            onClickDeleteFavourite={onClickDeleteFavourite}
            storeId={params.storeId}
          />
        </Col>
      }
    )
  }

  const renderBestSellers = () => {
    if (!bestSellers?.results?.length)
      return <p className="no-products-found text-center">No data found</p>

    return bestSellers?.results?.slice(0, 10).map(
      (
        {
          product: {
            brand,
            category,
            description,
            name,
            price,
            variation: { images }
          },
          id,
          favourite
        },
        index
      ) => (
        <Col xs="4" lg="2" className="mb-4" key={id}>
          <BestSeller
            id={id}
            product={{ brand, name, price }}
            // flavour={flavour?.name}
            img={images?.[0]?.image}
            // productskuId={productskuId}
            // favourite={favourite}
            loading={markingFavourite}
            // product={product}
            // flavour={flavour?.name}
            // img={images?.[0]?.image}
            // productskuId={productskuId}
            // favourite={favourite}
            onClickFavourite={onClickFavourite}
            onClickDeleteFavourite={onClickDeleteFavourite}
          />
        </Col>
      )
    )
  }

  return (
    <div
      className="produts-wrapper"
      ref={node}
      style={{ height: "100vh", overflowY: "scroll" }}
    >
      <StoreDetail
        name={store?.name}
        phone={store?.phone_number}
        address={store?.address}
        distance={store?.distance}
        logo={store?.logo}
        review_url={store?.review_url}
        storeDetails={store.payload}
        id={favouriteId}
        loading={storeLoading}
        setFavouriteId={setFavouriteId}
        onClickFavouriteStore={onClickFavouriteStore}
        onClickUnfavouriteStore={onClickUnfavouriteStore}
        show={showBestSellers}
        clearProductContext={clearProductContext}
      />
      <Filters
        searchText={searchText}
        filterLabels={filterLabels}
        onClickHandle={onClickFilterType}
        onChangeHandle={onChangeHandle}
      />

      <div className="products p-3 container">
        {showBestSellers && (
          <>
            <Row className="">
              <Col>
                <h4>Best sellers</h4>
              </Col>
              <Col className="text-end">
                <img src={assets.forwardArrow} />
              </Col>
            </Row>
            <Row className="top-10-products">{renderBestSellers()}</Row>
          </>
        )}
        <Row className="mb-1">
          <Col>
            <h4>All Products</h4>
          </Col>
        </Row>
        <Row style={{ height: "70vh", overflowY: "auto" }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreHandler}
            hasMore={true}
            useWindow={false}
            initialLoad={false}
          >
            <Row className="all-products">
              {renderProducts()} <Loader loading={isLoading} />
            </Row>
          </InfiniteScroll>
        </Row>
      </div>
      {showSortBy && (
        <FiltersBottomDrawer
          title="Sort by"
          type="ordering"
          radioOptions={sortByOptions}
          onChange={onChangeSortBy}
          onHide={hideFilter}
          onApply={onApplyFilters}
          reset={onResetSortBy}
        />
      )}
      {showBrand && (
        <FiltersBottomDrawer
          title="Brand"
          type="brand"
          checkOptions={brands}
          onClickCheckOption={onChangeBrand}
          onHide={hideFilter}
          onApply={onApplyFilters}
          reset={onResetBrand}
        />
      )}
      {showFlavorProfile && (
        <FiltersBottomDrawer
          title="Flavor Profile"
          type="classificationvalue__in"
          checkOptions={flavors}
          onClickCheckOption={onChangeFlavor}
          onHide={hideFilter}
          onApply={onApplyFilters}
          reset={onResetFlavors}
        />
      )}
      {showCategory && (
        <FiltersBottomDrawer
          title="Category"
          titleSecondary="Sub Categories"
          type="category"
          radioOptions={categories}
          checkOptions={subCategories}
          onChange={onChangeCategory}
          onClickCheckOption={onSelectSubCategory}
          onHide={hideFilter}
          onApply={onApplyFilters}
          reset={onResetCategory}
        />
      )}
      <Loader loading={storeLoading} />
    </div>
  )
}
