import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { Loader } from "../../components/Loader"
import { Col, Row } from "react-bootstrap"

function NotifyPopupModal(props) {
  const { onHide, onClickYes, loading } = props
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="notify-popup"
    >
      <Modal.Body>
        <p style={{ whiteSpace: "nowrap", marginBottom: "2rem" }}>
          Turn on notifications for this item
        </p>
        <Row className="btns">
          <Col xs="6">
            <Button variant="outline-secondary" onClick={onHide}>
              Cancel
            </Button>
          </Col>
          <Col xs="6">
            <Button className="yes" disabled={loading} onClick={onClickYes}>
              Yes
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Loader loading={loading} />
    </Modal>
  )
}

export function NotifyPopup({ show, onHide, onClickNotify, loading }) {
  return (
    <NotifyPopupModal
      show={show}
      onHide={onHide}
      onClickYes={onClickNotify}
      loading={loading}
    />
  )
}
