export const SORT_BY_OPTIONS = [
  { label: "Alphabetically, A-Z", value: "variation__product__name", checked: false },
  { label: "Alphabetically, Z-A", value: "-variation__product__name", checked: false },
  { label: "Price Low to High", value: "variation__product__price", checked: false },
  { label: "Price High to Low", value: "-variation__product__price", checked: false }
]
export const MILLIGRAM_OPTIONS = [
  {
    label: "Up to 2ml",
    value: { mg_max: "2" },
    checked: false
  },
  {
    label: "2ml to 4ml",
    value: { mg_min: "2", mg_max: "4" },
    checked: false
  },
  {
    label: "5ml",
    value: { mg: "5" },
    checked: false
  },
  {
    label: "6ml",
    value: { mg: "6" },
    checked: false
  },
  {
    label: ">7ml",
    value: { mg_min: "7" },
    checked: false
  }
]
export const PUFF_COUNT_OPTIONS = [
  {
    label: "250 - 500 puffs",
    value: { puff_min: "250", puff_max: "500" },
    checked: false
  },
  {
    label: "800 - 1,000 puffs",
    value: { puff_min: "800", puff_max: "1000" },
    checked: false
  },
  {
    label: "1,200 - 1,600 puffs",
    value: { puff_min: "1200", puff_max: "1600" },
    checked: false
  },
  {
    label: "2,000 - 2,500 puffs",
    value: { puff_min: "2000", puff_max: "2500" },
    checked: false
  },
  {
    label: "3,000 puffs",
    value: { puff: "3000" },
    checked: false
  },
  {
    label: "> 4,000 Puffs",
    value: { puff_min: "4000" },
    checked: false
  }
]

export const NICOTINE_OPTIONS = [
  { label: "0% Nicotine", value: "0", checked: false },
  { label: "3% Nicotine", value: "3", checked: false },
  { label: "5% Nicotine", value: "5", checked: false },
  { label: "5.5% Nicotine", value: "5.5", checked: false },
  { label: "6% Nicotine", value: "6", checked: false }
]

export const FILTER_LABELS = [
  {
    label: "All",
    value: "all",
    active: true
  },
  {
    label: "Sort By",
    value: "ordering",
    active: false
  },
  // {
  //   label: "Brand",
  //   value: "brand",
  //   active: false
  // },
  {
    label: "Category",
    value: "category",
    active: false
  },
  {
    label: "Flavor",
    value: "classificationvalue__in",
    active: false
  }
]
