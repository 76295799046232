import React, { useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { assets, validation } from "../../utils"
import InputField from "../../components/InputField"
import { useAuthContext } from "../../contexts/AuthContext"
import { toast } from "react-toastify"

import "./Feedback.scss"

export const Feedback = () => {
  const nav = useNavigate()
  const { isLoading, addFeedback } = useAuthContext()
  const { control, trigger, register, reset, formState, handleSubmit } =
    useForm()

  const onSubmit = vals => {
    addFeedback(vals).then(() => {
      reset()
      // setShowToast(true)
      toast.success('Thank you for sharing your valuable feedback!')
      setTimeout(() => nav("/home/stores"), 1000)
    })
  }

  return (
    <Container className="feedbacks">
      <Link to="/home/stores" style={{ textDecoration: "none" }}>
        <Row>
          <Col className="mt-3 mb-5" xs="12">
            <img src={assets.backArrow} />
          </Col>
          <Col className="text-center">
            <h4>Share your feedback</h4>
          </Col>
        </Row>
      </Link>
      <Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Col xs="12">
            <InputField
              label="Title"
              name="title"
              placeholder="Title"
              rules={{
                required: "Title required!",
                validate: { ...validation.name }
              }}
              compType=""
              control={control}
              trigger={trigger}
              // className="applicationScreenInput"
            />
          </Col>
          <Col xs="12">
            <Form.Group
              className="mb-4"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                placeholder="Message"
                rows={3}
                {...register("message", { required: true })}
              />
            </Form.Group>
          </Col>
          <Col xs="12" className="text-center">
            <Button
              variant="dark"
              type="submit"
              disabled={!formState.isValid || isLoading}
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Col>
        </form>
      </Row>
    </Container>
  )
}
