import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { Loader } from "../../components/Loader"
import { useCartContext } from "../../contexts/CartContext"
import { assets } from "../../utils"
import { CartItems } from "./CartItems"
import { Header } from "./Header"
import { ItemStore } from "./ItemStore"
import { PaymentProcessing } from "./PaymentProcessing"
import { useNavigate } from "react-router-dom"

export const Cart = () => {
  const {
    isLoading,
    cartItems,
    cartTotal,
    getCart,
    updateCartItem,
    deleteCartItem,
    cartItemTotal,
    pickupOptions,
    onChangePickupType,
    checkoutOrder,
    cartPriceDetails,
    setGroupedStoreAndCart
  } = useCartContext()
  const [groupedItems, setGroupedItems] = useState([])
  const [formAction, setFormAction] = useState("")
  const [paymentToken, setPaymentToken] = useState("")
  const nav = useNavigate();

  const onClickQuantityChange = (id, payload) => {
    updateCartItem(id, payload).then(() => getCart())
  }

  const onDeleteCartItem = id => {
    deleteCartItem(id).then(() => getCart())
  }

  const onClickPayNow = () => {
    // const payload = {}
    // const pickupType = pickupOptions.find(({ checked }) => checked)?.value
    // payload.pickup_type = pickupType
    // checkoutOrder(payload).then(response => {
    //   const { payment_gatewayurl, token } = response
    //   setFormAction(payment_gatewayurl)
    //   setPaymentToken(token)
    // })

    nav('/home/payment')
  }

  useEffect(() => {
    const groupedItems = cartItems.reduce((acc, obj) => {
      const key = obj.productstock.store.name
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
    setGroupedItems(groupedItems)
    setGroupedStoreAndCart(groupedItems)
  }, [cartItems])

  useEffect(() => {
    getCart()
  }, [])

  useEffect(() => {
    let timeOut
    if (paymentToken && formAction) {
      timeOut = setTimeout(clickButton, 3000)
      function clickButton() {
        document.getElementById("btnContinue").click()
      }
    }
    return () => clearTimeout(timeOut)
  }, [formAction, paymentToken])

  return (
    <>
      {formAction && paymentToken ? (
        <PaymentProcessing
          formAction={formAction}
          paymentToken={paymentToken}
        />
      ) : (
        <Container className="cart">
          <Header />
          <Row>
            {Object.keys(groupedItems).length > 0 ? (
              <>
                <Col xs="12" lg="7" className="cart-items-wrapper">
                  {Object.keys(groupedItems).map(key => {
                    const items = groupedItems[key].sort((a,b)=>a.id-b.id)
                    const store = items?.[0]?.productstock?.store
                    return (
                      <div key={key}>
                        <ItemStore store={store} />
                        <h5>Your cart</h5>

                        <CartItems
                          items={items}
                          onClickQuantityChange={onClickQuantityChange}
                          onDeleteCartItem={onDeleteCartItem}
                        />
                      </div>
                    )
                  })}
                </Col>
                <Col lg="1"></Col>
                <Col xs="12" lg="4" className="eta-wrapper">
                  <Row>
                    <Col xs="8" sm="8" className="mb-3">
                      <Form.Control type="text" placeholder="Add promo code" />
                    </Col>
                    <Col xs="4">
                      <Button className="btn-validate">Validate</Button>
                    </Col>
                  </Row>
                  <Row className="pickup-type">
                    {pickupOptions.map(({ label, value, checked }) => (
                      <Col xs="12" className="mb-3" key={value}>
                        <Form.Check
                          type="radio"
                          id={`default-${label}`}
                          label={label}
                          checked={checked}
                          onChange={() => onChangePickupType(value)}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Row className="cart-subtotal-details mt-3">
                    <Col
                      xs="12"
                      lg="8"
                      className="price-item d-flex align-items-center justify-content-between"
                    >
                      <p>Subtotal:</p>
                      <p>{`$ ${cartPriceDetails.subTotal}`}</p>
                    </Col>
                    <Col
                      xs="12"
                      lg="8"
                      className="price-item d-flex align-items-center justify-content-between"
                    >
                      <p>Tax:</p>
                      <p>{`$ ${cartPriceDetails.salesTax}`}</p>
                    </Col>
                    <Col
                      xs="12"
                      lg="8"
                      className="price-item d-flex align-items-center justify-content-between"
                    >
                      <p>Cecet Tax:</p>
                      <p>{`$ ${cartPriceDetails.nicotineTax}`}</p>
                    </Col>
                    <Col
                      xs="12"
                      lg="8"
                      className="price-item d-flex align-items-center justify-content-between"
                    >
                      <p>Discount:</p>
                      <p>{`$ ${cartPriceDetails.discount}`}</p>
                    </Col>
                    <Col
                      xs="12"
                      lg="8"
                      className="price-item d-flex align-items-center justify-content-between"
                    >
                      <p>Promo Code:</p>
                      <p>$ 0.0</p>
                    </Col>
                    <hr />
                    <Col
                      xs="12"
                      lg="8"
                      className="total-price-item d-flex align-items-center justify-content-between"
                    >
                      <p>{`Total (${cartItemTotal} items):`}</p>
                      <p>{`$ ${cartPriceDetails.priceToPay}`}</p>
                    </Col>
                    <Row>
                    <Col xs="1">
                      <img src={assets.watchIcon} />
                    </Col>
                    <Col xs="11" className="mb-3">
                      <span className="eta">
                        Estimated time for pick up: <b>10 min</b>
                      </span>
                    </Col>
                  </Row>
                  </Row>
                  <Row className="positionfixed">
                    <Col xs="6" md="12" className="pb-3">
                      <div className="total-price">
                        <p className="text-total">Total</p>
                        <p className="text-price">{`$ ${cartPriceDetails.priceToPay}`}</p>
                      </div>
                    </Col>
                    <Col xs="6" md="12">
                      <Button
                        className="btn-paynow"
                        disabled={cartItemTotal === 0 || isLoading}
                        onClick={onClickPayNow}
                      >
                        Pay now
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <Col className="text-center">
                <div className="min-h-100">
                  <p>Your Cart is Empty!</p>
                </div>
              </Col>
            )}
          </Row>
          <Loader loading={isLoading} />
        </Container>
      )}
    </>
  )
}
